<script setup lang="ts">
import { useArticleKindOneStore } from '@/stores/cms/articleKindStore'
import { isArticleKindStandard } from '@/types/cms/ArticleKind/ArticleKindStandard'
import type { CollabRoom } from '@/types/Collab'
import { ARow, type CollabStatusType } from '@anzusystems/common-admin'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'

withDefaults(
  defineProps<{
    collabRoom: CollabRoom
    collabStatus: CollabStatusType
    readonly: boolean
    isModerator: boolean
  }>(),
  {}
)

const { t } = useI18n()

const articleKindOneStore = useArticleKindOneStore()
const { article } = storeToRefs(articleKindOneStore)
</script>

<template>
  <ARow v-if="isArticleKindStandard(article)">
    <VSwitch
      v-model="article.flagsStandard.enableAds"
      :disabled="readonly || !isModerator"
      :label="t('cms.articleKind.model.flagsStandard.enableAds')"
    />
    <VSwitch
      v-model="article.flagsStandard.enableAdsInContent"
      :disabled="readonly || !isModerator"
      :label="t('cms.articleKind.model.flagsStandard.enableAdsInContent')"
    />
    <VSwitch
      v-model="article.flagsStandard.enableVideoAdsInContent"
      :disabled="readonly || !isModerator"
      :label="t('cms.articleKind.model.flagsStandard.enableVideoAdsInContent')"
    />
    <VSwitch
      v-model="article.flagsStandard.prAuthor"
      :disabled="readonly || !isModerator"
      :label="t('cms.articleKind.model.flagsStandard.prAuthor')"
    />
  </ARow>
</template>
