import { useContentItemKindFactory } from '@/model/cms/factory/ContentItemKind/ContentItemKindFactory'
import { SYSTEM_CMS } from '@/model/systems'
import type { AutoDistribution, GroupedAutoDistributions } from '@/types/cms/AutoDistribution'
import { AutoDistributionTypeDefault } from '@/types/cms/AutoDistribution'
import { ContentItemDiscriminatorDefault } from '@/types/cms/ContentItemKind/ContentItemKind'
import type { LayoutTemplate } from '@/types/cms/LayoutTemplate'
import { LimitedDisplayTypeDefault } from '@/types/cms/LimitedDisplayType'
import { dateTimeNow, isNumber } from '@anzusystems/common-admin'

const { createContentItemKind } = useContentItemKindFactory()

export function useAutoDistributionFactory() {
  const createAutoDistribution = (): AutoDistribution => {
    return {
      id: 0,
      texts: {
        title: '',
        description: '',
      },
      dates: {
        showAtFrom: null,
        showAtUntil: null,
      },
      attributes: {
        type: AutoDistributionTypeDefault,
        limitedDisplayType: LimitedDisplayTypeDefault,
      },
      enabled: true,
      targetPosition: null,
      positionInsideTargetPosition: 0,
      siteGroup: null,
      site: null,
      rubric: null,
      keywords: [],
      contentItem: createContentItemKind(ContentItemDiscriminatorDefault),
      createdAt: dateTimeNow(),
      modifiedAt: dateTimeNow(),
      createdBy: null,
      modifiedBy: null,
      _resourceName: 'autoDistribution',
      _system: SYSTEM_CMS,
    }
  }

  const createGroupedAutoDistributions = (autoDistributions: AutoDistribution[], layoutTemplate: LayoutTemplate) => {
    const groupedAutoDistributions: GroupedAutoDistributions = {}
    for (const layoutTemplateTargetPosition of layoutTemplate.layoutTemplateTargetPositions) {
      groupedAutoDistributions[layoutTemplateTargetPosition.targetPosition] = []
    }
    for (const autoDistribution of autoDistributions) {
      if (
        isNumber(autoDistribution.targetPosition) &&
        Object.hasOwn(groupedAutoDistributions, autoDistribution.targetPosition)
      ) {
        groupedAutoDistributions[autoDistribution.targetPosition].push(autoDistribution)
      }
    }
    return groupedAutoDistributions
  }

  return {
    createAutoDistribution,
    createGroupedAutoDistributions,
  }
}
