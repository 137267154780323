import {
  type FilterBag,
  type IntegerId,
  isUndefined,
  type Pagination,
  useAlerts,
  type ValueObjectOption
} from '@anzusystems/common-admin'
import { ROUTE } from '@/router/routes'
import {
  createArticleIntention,
  deleteArticleIntention,
  fetchArticleIntention,
  fetchArticleIntentionList, fetchArticleIntentionListByIds,
  updateArticleIntention
} from '@/services/api/cms/articleIntentionApi'
import useVuelidate from '@vuelidate/core'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useArticleIntentionOneStore } from '@/stores/cms/articleIntentionStore'
import type { ArticleIntention } from '@/types/cms/Task'
import { ArticleIntentionValidationSymbol } from '@/views/cms/articleIntention/composables/articleIntentionValidations'

const { showValidationError, showRecordWas, showErrorsDefault } = useAlerts()

const datatableHiddenColumns = ref<Array<string>>([])
const listLoading = ref(false)
const detailLoading = ref(false)
const saveButtonLoading = ref(false)

export const useArticleIntentionListActions = () => {
  const listItems = ref<ArticleIntention[]>([])

  const fetchList = async (pagination: Pagination, filterBag: FilterBag) => {
    listLoading.value = true
    try {
      listItems.value = await fetchArticleIntentionList(pagination, filterBag)
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      listLoading.value = false
    }
  }

  return {
    datatableHiddenColumns,
    listLoading,
    fetchList,
    listItems,
  }
}

export const useArticleIntentionDetailActions = () => {
  const articleIntentionOneStore = useArticleIntentionOneStore()
  const { articleIntention } = storeToRefs(articleIntentionOneStore)

  const fetchData = async (id: IntegerId) => {
    detailLoading.value = true
    try {
      articleIntention.value = await fetchArticleIntention(id)
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      detailLoading.value = false
    }
  }

  return {
    articleIntention,
    detailLoading,
    fetchData,
    resetStore: articleIntentionOneStore.reset,
  }
}

export const useArticleIntentionCreateEditActions = () => {
  const v$ = useVuelidate({ $scope: ArticleIntentionValidationSymbol })
  const articleIntentionOneStore = useArticleIntentionOneStore()
  const { articleIntention } = storeToRefs(articleIntentionOneStore)

  const fetchData = async (id: IntegerId) => {
    detailLoading.value = true
    try {
      articleIntention.value = await fetchArticleIntention(id)
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      detailLoading.value = false
    }
  }

  const onUpdate = async (onSuccess: ((articleIntention: ArticleIntention) => void) | undefined = undefined) => {
    try {
      saveButtonLoading.value = true
      v$.value.$touch()
      if (v$.value.$invalid) {
        showValidationError()
        saveButtonLoading.value = false
        return
      }
      const res = await updateArticleIntention(
        articleIntentionOneStore.articleIntention.id,
        articleIntentionOneStore.articleIntention
      )
      showRecordWas('updated')
      if (!isUndefined(onSuccess)) onSuccess(res)
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      saveButtonLoading.value = false
    }
  }

  const onCreate = async (onSuccess: ((articleIntention: ArticleIntention) => void) | undefined = undefined) => {
    try {
      saveButtonLoading.value = true
      v$.value.$touch()
      if (v$.value.$invalid) {
        showValidationError()
        saveButtonLoading.value = false
        return
      }
      const articleIntentionRes = await createArticleIntention(articleIntentionOneStore.articleIntention)
      showRecordWas('created')
      if (!isUndefined(onSuccess)) onSuccess(articleIntentionRes)
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      saveButtonLoading.value = false
    }
  }

  return {
    saveButtonLoading,
    detailLoading,
    articleIntention,
    fetchData,
    onUpdate,
    onCreate,
    resetStore: articleIntentionOneStore.reset,
  }
}

export const useArticleIntentionDeleteActions = () => {
  const router = useRouter()

  const onDelete = async (id: IntegerId) => {
    try {
      await deleteArticleIntention(id)
      showRecordWas('deleted')
      router.push({ name: ROUTE.CMS.ARTICLE_INTENTION.LIST })
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      // closeDeleteDialog()
    }
  }

  return {
    onDelete,
  }
}

export const useArticleIntentionSelectActions = () => {
  const fetchItems = async (pagination: Pagination, filterBag: FilterBag) => {
    const articleIntentions = await fetchArticleIntentionList(pagination, filterBag)

    return <ValueObjectOption<IntegerId>[]>articleIntentions.map((articleIntention: ArticleIntention) => ({
      title: articleIntention.name,
      value: articleIntention.id,
    }))
  }

  const fetchItemsByIds = async (ids: IntegerId[]) => {
    const articleIntentions = await fetchArticleIntentionListByIds(ids)

    return <ValueObjectOption<IntegerId>[]>articleIntentions.map((articleIntention: ArticleIntention) => ({
      title: articleIntention.name,
      value: articleIntention.id,
    }))
  }

  return {
    fetchItems,
    fetchItemsByIds,
  }
}
