<script setup lang="ts">
import { useArticleKindOneStore } from '@/stores/cms/articleKindStore'
import { isArticleKindStandard } from '@/types/cms/ArticleKind/ArticleKindStandard'
import type { CollabRoom } from '@/types/Collab'
import NewsletterRemoteAutocomplete from '@/views/cms/newsletter/components/NewsletterRemoteAutocomplete.vue'
import { ARow, type CollabStatusType } from '@anzusystems/common-admin'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'

withDefaults(
  defineProps<{
    collabRoom: CollabRoom
    collabStatus: CollabStatusType
    readonly: boolean
    isModerator: boolean
  }>(),
  {}
)

const { t } = useI18n()

const articleKindOneStore = useArticleKindOneStore()
const { article } = storeToRefs(articleKindOneStore)
</script>

<template>
  <div v-if="isArticleKindStandard(article)">
    <ARow>
      <VSwitch
        v-model="article.attributesStandard.inTrending"
        :disabled="readonly"
        :label="t('cms.articleKind.model.attributesStandard.inTrending')"
      />
      <VSwitch
        v-model="article.flagsStandard.enableForum"
        :disabled="readonly"
        :label="t('cms.articleKind.model.flagsStandard.enableForum')"
      />
    </ARow>
    <NewsletterRemoteAutocomplete
      v-model="article.newsletter"
      :label="t('cms.articleKind.model.newsletter')"
    />
  </div>
</template>
