import type { Rubric } from '@/types/cms/Rubric'
import { useValidate } from '@anzusystems/common-admin'
import useVuelidate from '@vuelidate/core'
import type { Ref } from 'vue'

export const RubricValidationSymbol = Symbol.for('anzu:cms:rubric-validation-scope')

export function useRubricValidation(rubric: Ref<Rubric>) {
  const { maxLength, minLength, minValue, maxValue, required, slug } = useValidate()

  const rules = {
    rubric: {
      texts: {
        title: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(255),
        },
        shortTitle: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(255),
        },
        description: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(255),
        },
      },
      seo: {
        title: {
          maxLength: maxLength(255),
        },
        description: {
          maxLength: maxLength(4096),
        },
        slug: {
          slug,
          maxLength: maxLength(255),
        },
        postfix: {},
      },
      analytics: {
        rempPropertyToken: {
          maxLength: maxLength(255),
        },
        gtmId: {
          maxLength: maxLength(255),
        },
      },
      site: {
        required,
        minValue: minValue(1),
      },
      siteGroup: {
        required,
        minValue: minValue(1),
      },
      linkedList: {},
      advertSettings: {},
      designSettings: {},
      mainPage: {},
      settings: {
        overrideParentContentLockSettings: {},
        lockAfterPercentage: {
          minValue: minValue(0),
          maxValue: maxValue(1),
        },
      },
    },
  }
  const v$ = useVuelidate(rules, { rubric }, { $scope: RubricValidationSymbol })

  return {
    v$,
  }
}
