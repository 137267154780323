import { useContentItemKindFactory } from '@/model/cms/factory/ContentItemKind/ContentItemKindFactory'
import { ContentItemDiscriminator } from '@/types/cms/ContentItemKind/ContentItemKind'
import type { ContentItemKindTrendingArticleList } from '@/types/cms/ContentItemKind/ContentItemKindTrendingArticleList'

const { createAbstractContentItemKind } = useContentItemKindFactory()

export function useContentItemKindTrendingArticleListFactory() {
  const create = (): ContentItemKindTrendingArticleList => {
    return {
      title: '',
      groupList: null,
      selectedArticleGroup: null,
      ...createAbstractContentItemKind(),
      discriminator: ContentItemDiscriminator.TrendingArticleList,
      _resourceName: 'contentItemKindTrendingArticleList',
    }
  }

  return {
    create,
  }
}
