import { useContentItemKindFactory } from '@/model/cms/factory/ContentItemKind/ContentItemKindFactory'
import { ContentItemDiscriminator } from '@/types/cms/ContentItemKind/ContentItemKind'
import type { ContentItemKindNewsroomSection } from '@/types/cms/ContentItemKind/ContentItemKindNewsroomSection'

const { createAbstractContentItemKind } = useContentItemKindFactory()

export function useContentItemKindNewsroomSectionFactory() {
  const create = (): ContentItemKindNewsroomSection => {
    return {
      ...createAbstractContentItemKind(),
      title: '',
      siteGroup: null,
      discriminator: ContentItemDiscriminator.NewsroomSection,
      _resourceName: 'contentItemKindNewsroomSection',
    }
  }

  return {
    create,
  }
}
