import { ACL } from '@/composables/auth/auth'
import { ROUTE } from '@/router/routes'
import type { RouteRecordRaw } from 'vue-router'

export const cmsArticleAdvertSettingsRoutes: RouteRecordRaw[] = [
  {
    path: '/article-advert-settings',
    name: ROUTE.CMS.ARTICLE_ADVERT_SETTINGS.LIST,
    component: () => import('@/views/cms/articleAdvertSettings/ArticleAdvertSettingsListView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/article-advert-settings/create',
    name: ROUTE.CMS.ARTICLE_ADVERT_SETTINGS.CREATE,
    component: () => import('@/views/cms/articleAdvertSettings/ArticleAdvertSettingsCreateView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_ADVERT_SETTINGS_CREATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/article-advert-settings/:id(\\d+)/edit',
    name: ROUTE.CMS.ARTICLE_ADVERT_SETTINGS.EDIT,
    component: () => import('@/views/cms/articleAdvertSettings/ArticleAdvertSettingsEditView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_ADVERT_SETTINGS_READ, ACL.CMS_ADVERT_SETTINGS_UPDATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/article-advert-settings/:id(\\d+)',
    name: ROUTE.CMS.ARTICLE_ADVERT_SETTINGS.DETAIL,
    component: () => import('@/views/cms/articleAdvertSettings/ArticleAdvertSettingsDetailView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_ADVERT_SETTINGS_READ],
      layout: 'AppLayoutDrawer',
    },
  },
]
