import { EmbedEntity } from '@/components/anzutap/types/Embed'
import type CustomForm from '@/components/cms/CustomForm.vue'
import { useArticleMultiTitleFactory } from '@/model/cms/factory/ArticleMultiTitleFactory'
import { ArticleDiscriminator } from '@/model/cms/valueObject/ArticleDiscriminator'
import { ArticleStatus, type ArticleStatusType } from '@/model/cms/valueObject/ArticleStatus'
import { ROUTE } from '@/router/routes'
import {
  apiUpdatePromoLinkItems,
  changeArticleKindStatus,
  createArticleKind,
  createArticleKindDraftFromPublished,
  createArticleKindMinute,
  createArticleKindStandard,
  deleteArticleKind,
  fetchArticleKind,
  fetchArticleKindListByDocId,
  fetchArticleKindStandardListVersionData,
  updateArticleKind,
} from '@/services/api/cms/articleKindApi'
import { upsertArticleMultiTitles } from '@/services/api/cms/articleMultiTitleApi'
import { removeTextsOverridesBoxItems } from '@/services/api/cms/boxItemApi'
import { fetchEmbedList } from '@/services/api/cms/embed/embedApi'
import { useArticleKindOneStore } from '@/stores/cms/articleKindStore'
import { useArticleMultiTitleOneStore } from '@/stores/cms/articleMultiTitleStore'
import { useEmbedStore } from '@/stores/cms/embedStore'
import type { ArticleKind } from '@/types/cms/ArticleKind/ArticleKind'
import {
  type ArticleKindStandard,
  type ArticleStagesChangeCollabUpdate,
  isArticleKindStandard,
} from '@/types/cms/ArticleKind/ArticleKindStandard'
import type { ArticleMinuteCreateDto, ArticleStandardCreateDto } from '@/types/cms/ArticleKind/ArticleMinuteCreateDto'
import type { PromoLinkItem } from '@/types/cms/PromoLink'
import type { CollabRoom } from '@/types/Collab'
import { useMainBarDialogsStore } from '@/views/cms/article/components/mainBarButtons/mainBarDialogsStore'
import { useArticleWidget } from '@/views/cms/article/components/widgets/articleWidget'
import { useArticleRelatedEntitiesActions } from '@/views/cms/article/composables/articleRelatedEntitiesActions'
import { useArticleMultiTitleCreateAndEditActions } from '@/views/cms/articleMultiTitle/composables/articleMultiTitleActions'
import { useCachedAuthors } from '@/views/cms/author/composables/cachedAuthors'
import { useCachedDesks } from '@/views/cms/desk/composables/cachedDesks'
import { useCachedPromoLinks } from '@/views/cms/promoLink/composables/cachedPromoLinks'
import { useCachedRubrics } from '@/views/cms/rubric/composables/cachedRubrics'
import { getSiteFromCachedOrLoad, useCachedSites } from '@/views/cms/site/composables/cachedSites'
import { useCachedUsers } from '@/views/cms/user/composables/cachedUsers'
import {
  type DocId,
  type FilterBag,
  type IntegerId,
  isNull,
  isNumber,
  isString,
  isUndefined,
  objectSetValueByPath,
  type Pagination,
  type RecordWasType,
  useAlerts,
  useCollabAnyDataChange,
  useCollabHelpers,
} from '@anzusystems/common-admin'
import { type Validation } from '@vuelidate/core'
import type { Fn } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import { onBeforeUnmount, type Ref, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStages } from '@/views/cms/stage/composables/stagesAll'

const { addToCachedUsers, fetchCachedUsers } = useCachedUsers()
const { addToCachedAuthors, fetchCachedAuthors } = useCachedAuthors()
const { addToCachedSites, fetchCachedSites } = useCachedSites()
const { addToCachedRubrics, fetchCachedRubrics } = useCachedRubrics()
const { addToCachedDesks, fetchCachedDesks } = useCachedDesks()
const { addToCachedPromoLinks, fetchCachedPromoLinks } = useCachedPromoLinks()
const { showValidationError, showRecordWas, showErrorsDefault, showError } = useAlerts()

const datatableHiddenColumns = ref<Array<string>>([
  'id',
  'docId',
  'version',
  'discriminator',
  'site',
  'desk',
  'dates.publicPublishedAt',
  'dates.expireAt',
  'modifiedAt',
  'stages',
])
const listLoading = ref(false)
const detailLoading = ref(true)
const saveButtonLoading = ref(false)
const publishButtonLoading = ref(false)
const createButtonLoading = ref(false)
const updatePromoLinkItemsLoading = ref(false)
const customFormInstance = ref<InstanceType<typeof CustomForm> | null>(null)
const expanded = ref<string[]>([]) // todo fix when vuetify fixes its type, it should be IntegerId[]

export const COLLAB_FIELD_PREFIX_STAGES_CHANGE = 'articleStagesChange:'
export const COLLAB_FIELD_NAME_LOCKING_VERSION_CHANGE = 'articleLockingVersion'

export const useArticleKindListActions = () => {
  const articleKindOneStore = useArticleKindOneStore()
  const { listItems } = storeToRefs(articleKindOneStore)
  const { loadStages } = useStages()

  const fetchList = async (pagination: Pagination, filterBag: FilterBag) => {
    listLoading.value = true
    try {
      const res = await fetchArticleKindStandardListVersionData(pagination, filterBag)
      listItems.value = res.items
      listItems.value.forEach((item) => {
        addToCachedSites(item.site)
        addToCachedRubrics(item.rubric)
        if (isArticleKindStandard(item)) {
          addToCachedDesks(item.desk)
          addToCachedAuthors(item.articleAuthors.map((articleAuthor) => articleAuthor.author))
        }
        if (item.versionsData) {
          addToCachedSites(item.versionsData.site)
          addToCachedRubrics(item.versionsData.rubric)
          addToCachedDesks(item.versionsData.desk)
          addToCachedAuthors(item.articleAuthors.map((articleAuthor) => articleAuthor.author))
        }
      })
      expanded.value = res.hasVersionData as unknown as string[] // todo fix when vuetify fixes its type
      fetchCachedSites()
      fetchCachedRubrics()
      fetchCachedDesks()
      fetchCachedAuthors()
      loadStages()
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      listLoading.value = false
    }
  }

  return {
    expanded,
    datatableHiddenColumns,
    listLoading,
    fetchList,
    listItems,
  }
}

export const useArticleKindDetailActions = () => {
  const articleKindOneStore = useArticleKindOneStore()
  const embedStore = useEmbedStore()
  const mainBarDialogsStore = useMainBarDialogsStore()
  const { article, articleSite, bodyEditorReady } = storeToRefs(articleKindOneStore)
  const { lazyLoadUserPinnedWidgets } = useArticleWidget()
  const { fetchArticleMultiTitles } = useArticleMultiTitleCreateAndEditActions()
  const { fetchRelatedEntities } = useArticleRelatedEntitiesActions()

  onBeforeUnmount(() => {
    bodyEditorReady.value = false
    embedStore.reset()
    articleKindOneStore.reset()
  })

  const fetchData = async (id: IntegerId, fetchEmbeds = false) => {
    detailLoading.value = true
    embedStore.setCurrentEntity(EmbedEntity.Article, id)
    try {
      await lazyLoadUserPinnedWidgets()
      const articleRes = await fetchArticleKind(id)
      window.history.replaceState(window.history.state, '', `/article/${articleRes.docId}/${articleRes.version}`)
      mainBarDialogsStore.article = articleRes
      articleSite.value = await getSiteFromCachedOrLoad(articleRes.site)
      embedStore.setCurrentSiteGroup(articleRes.siteGroup)
      addToCachedUsers(articleRes.owners, articleRes.firstPublishedBy, articleRes.createdBy, articleRes.modifiedBy)
      addToCachedRubrics(articleRes.rubric)
      addToCachedPromoLinks(articleRes.promoLinkItems.map((promoLinkItem: PromoLinkItem) => promoLinkItem.promoLink))
      addToCachedPromoLinks(articleRes.disabledPromoLinks)
      articleRes.articleAuthors.forEach((item) => {
        addToCachedAuthors(item.author)
      })
      if (isArticleKindStandard(articleRes)) {
        addToCachedDesks(articleRes.desk)
      }
      articleKindOneStore.article = articleRes
      fetchCachedUsers()
      fetchCachedRubrics()
      fetchCachedAuthors()
      fetchCachedPromoLinks()
      fetchCachedDesks()
      if (fetchEmbeds) {
        const embedsRes = await fetchEmbedList(id, EmbedEntity.Article)
        embedStore.addEmbeds(embedsRes)
      }
      await Promise.allSettled([fetchArticleMultiTitles(id), fetchRelatedEntities(id)])
      bodyEditorReady.value = true
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      detailLoading.value = false
    }
  }

  return {
    bodyEditorReady,
    article,
    detailLoading,
    fetchData,
    resetStore: articleKindOneStore.reset,
  }
}

export const useArticleKindCreateActions = () => {
  const router = useRouter()

  const onCreate = async (
    articleData: ArticleMinuteCreateDto,
    v$: Ref<Validation>,
    callbackSuccess: (() => void) | undefined = undefined
  ) => {
    try {
      createButtonLoading.value = true
      v$.value.$touch()
      if (v$.value.$invalid) {
        createButtonLoading.value = false
        showValidationError()
        return
      }
      const articleRes = await createArticleKind(articleData)
      showRecordWas('created')
      if (!isUndefined(callbackSuccess)) callbackSuccess()
      if (articleData.discriminator === ArticleDiscriminator.Minute) {
        router.push({ name: ROUTE.CMS.ARTICLE_MINUTE.EDIT, params: { id: articleRes.id } })
        return
      }
      router.push({ name: ROUTE.CMS.ARTICLE.EDIT, params: { id: articleRes.id } })
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      createButtonLoading.value = false
    }
  }

  return {
    createButtonLoading,
    onCreate,
  }
}

export const useArticleKindStandardCreateActions = () => {
  const router = useRouter()

  const onCreate = async (
    articleData: ArticleStandardCreateDto,
    callbackSuccess: ((data: ArticleKindStandard) => void) | undefined = undefined
  ) => {
    try {
      createButtonLoading.value = true
      const articleRes = await createArticleKindStandard(articleData)
      showRecordWas('created')
      if (!isUndefined(callbackSuccess)) callbackSuccess(articleRes)
      router.push({ name: ROUTE.CMS.ARTICLE.EDIT, params: { id: articleRes.id } })
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      createButtonLoading.value = false
    }
  }

  return {
    createButtonLoading,
    onCreate,
  }
}

export const useArticleKindMinuteCreateActions = () => {
  const router = useRouter()

  const onCreate = async (
    articleData: ArticleMinuteCreateDto,
    v$: Ref<Validation>,
    callbackSuccess: (() => void) | undefined = undefined
  ) => {
    try {
      createButtonLoading.value = true
      const articleRes = await createArticleKindMinute(articleData)
      showRecordWas('created')
      if (!isUndefined(callbackSuccess)) callbackSuccess()
      router.push({ name: ROUTE.CMS.ARTICLE_MINUTE.EDIT, params: { id: articleRes.id } })
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      createButtonLoading.value = false
    }
  }

  return {
    createButtonLoading,
    onCreate,
  }
}

export const useArticleKindEditActions = () => {
  const router = useRouter()
  const articleKindOneStore = useArticleKindOneStore()
  const articleMultiTitleOneStore = useArticleMultiTitleOneStore()
  const embedStore = useEmbedStore()
  const mainBarDialogsStore = useMainBarDialogsStore()
  const { lazyLoadUserPinnedWidgets } = useArticleWidget()
  const { fetchArticleMultiTitles } = useArticleMultiTitleCreateAndEditActions()
  const { createUpsertMultiTitleDto } = useArticleMultiTitleFactory()
  const { fetchRelatedEntities } = useArticleRelatedEntitiesActions()

  const { article, articleSite, bodyEditorReady } = storeToRefs(articleKindOneStore)

  const fetchData = async (id: IntegerId, fetchEmbeds: boolean, collabRoomName: CollabRoom) => {
    detailLoading.value = true
    embedStore.setCurrentEntity(EmbedEntity.Article, id)
    embedStore.setCurrentRoomName(collabRoomName)
    try {
      await lazyLoadUserPinnedWidgets()
      const articleRes = await fetchArticleKind(id)
      window.history.replaceState(window.history.state, '', `/article/${articleRes.docId}/${articleRes.version}/edit`)
      mainBarDialogsStore.article = articleRes
      embedStore.setCurrentSiteGroup(articleRes.siteGroup)
      if (articleRes.status !== ArticleStatus.Draft) {
        detailLoading.value = false
        router.push({ name: ROUTE.CMS.ARTICLE.DETAIL, params: { id: articleRes.id } })
        return
      }
      articleSite.value = await getSiteFromCachedOrLoad(articleRes.site)
      addToCachedUsers(articleRes.firstPublishedBy, articleRes.createdBy, articleRes.modifiedBy)
      addToCachedPromoLinks(articleRes.promoLinkItems.map((promoLinkItem: PromoLinkItem) => promoLinkItem.promoLink))
      addToCachedPromoLinks(articleRes.disabledPromoLinks)
      if (isArticleKindStandard(articleRes)) {
        addToCachedDesks(articleRes.desk)
      }
      fetchCachedPromoLinks()
      fetchCachedUsers()
      fetchCachedDesks()
      if (fetchEmbeds) {
        const embedsRes = await fetchEmbedList(id, EmbedEntity.Article)
        embedStore.addEmbeds(embedsRes)
      }
      articleKindOneStore.article = articleRes
      await Promise.allSettled([fetchArticleMultiTitles(id), fetchRelatedEntities(id)])
      bodyEditorReady.value = true
    } catch (error) {
      showErrorsDefault(error)
      router.push({ name: ROUTE.CMS.ARTICLE.LIST })
    } finally {
      detailLoading.value = false
    }
  }

  const onUpdate = async () => {
    if (isUndefined(articleKindOneStore.vScope)) return
    const { createCollabRoom } = useCollabHelpers()
    const { changeCollabAnyData } = useCollabAnyDataChange(
      createCollabRoom(articleKindOneStore.article.id, 'article', 'cms')
    )
    try {
      saveButtonLoading.value = true
      const bodyUpdateWasSuccess = articleKindOneStore.updateBodyTextFromEditor()
      if (!bodyUpdateWasSuccess) {
        showError('Error: Unable to update article body text from editor.')
        saveButtonLoading.value = false
        return
      }
      const validCustomData = await customFormInstance.value?.validate()
      articleKindOneStore.vScope.$touch()
      if (articleKindOneStore.vScope.$invalid || !validCustomData) {
        showValidationError()
        saveButtonLoading.value = false
        return
      }
      if (articleMultiTitleOneStore.articleMultiTitles.length) {
        const upsertMultiTitleDto = createUpsertMultiTitleDto(
          articleKindOneStore.article.id,
          articleMultiTitleOneStore.articleMultiTitles
        )
        articleMultiTitleOneStore.articleMultiTitles = await upsertArticleMultiTitles(upsertMultiTitleDto)
      }
      if (articleMultiTitleOneStore.selectedBoxItemsToOverrideTexts.length) {
        await removeTextsOverridesBoxItems({ boxItems: articleMultiTitleOneStore.selectedBoxItemsToOverrideTexts })
        articleMultiTitleOneStore.selectedBoxItemsToOverrideTexts = []
      }
      const newArticle = await updateArticleKind(articleKindOneStore.article.id, articleKindOneStore.article)
      articleKindOneStore.article.lockingVersion = newArticle.lockingVersion
      changeCollabAnyData(COLLAB_FIELD_NAME_LOCKING_VERSION_CHANGE, newArticle.lockingVersion)
      showRecordWas('updated')
      // updatePromoLinkItems() todo
      fetchRelatedEntities(articleKindOneStore.article.id)
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      saveButtonLoading.value = false
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const updatePromoLinkItems = async () => {
    try {
      updatePromoLinkItemsLoading.value = true
      const articleRes = await apiUpdatePromoLinkItems(articleKindOneStore.article.id)
      article.value.promoLinkItems = articleRes.promoLinkItems
      addToCachedPromoLinks(articleRes.promoLinkItems.map((promoLinkItem: PromoLinkItem) => promoLinkItem.promoLink))
      fetchCachedPromoLinks()
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      updatePromoLinkItemsLoading.value = false
    }
  }

  const getStatusChangeVariant = (oldStatus: ArticleStatusType, newStatus: ArticleStatusType): RecordWasType => {
    if (oldStatus === ArticleStatus.Published && newStatus === ArticleStatus.Draft) {
      return 'unpublished'
    }
    if (newStatus === ArticleStatus.Ready) {
      return 'published'
    }
    return 'updated'
  }

  const changeStatus = async (article: ArticleKind, newStatus: ArticleStatusType, view = ROUTE.CMS.ARTICLE.DETAIL) => {
    try {
      const articleRes = await changeArticleKindStatus(article.id, newStatus)
      showRecordWas(getStatusChangeVariant(article.status, newStatus))
      articleKindOneStore.article = articleRes
      router.push({ name: view, params: { id: articleRes.id } })
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      //
    }
  }

  const saveAndPublish = async (article: ArticleKind) => {
    publishButtonLoading.value = true
    try {
      await onUpdate()
      await changeStatus(article, ArticleStatus.Ready)
    } catch (err) {
      showErrorsDefault(err)
    } finally {
      publishButtonLoading.value = false
    }
  }

  const createDraftFromPublished = async (article: ArticleKind, view = ROUTE.CMS.ARTICLE.DETAIL) => {
    try {
      const articleRes = await createArticleKindDraftFromPublished(article.id)
      showRecordWas('created')
      router.push({ name: view, params: { id: articleRes.id } })
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      //
    }
  }

  return {
    detailLoading,
    updatePromoLinkItemsLoading,
    saveButtonLoading,
    publishButtonLoading,
    article,
    customFormInstance,
    fetchData,
    onUpdate,
    changeStatus,
    saveAndPublish,
    createDraftFromPublished,
    resetStore: articleKindOneStore.reset,
  }
}

export const useArticleKindDeleteActions = () => {
  const router = useRouter()

  const onDelete = async (id: IntegerId) => {
    try {
      await deleteArticleKind(id)
      showRecordWas('deleted')
      router.push({ name: ROUTE.CMS.ARTICLE.LIST })
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      // closeDeleteDialog()
    }
  }

  return {
    onDelete,
  }
}

export const useArticleKindFindActions = () => {
  const findArticleLoading = ref(false)
  const foundArticle: Ref<ArticleKind | null> = ref(null)
  const findByDocId = async (docId: DocId) => {
    findArticleLoading.value = true
    let tempFoundArticle: ArticleKind | null = null
    const articles = await fetchArticleKindListByDocId(docId)
    articles.forEach((article) => {
      if (null === tempFoundArticle) {
        tempFoundArticle = article
        return
      }
      if (article.status !== ArticleStatus.Draft) {
        tempFoundArticle = article
      }
    })
    foundArticle.value = tempFoundArticle
    findArticleLoading.value = false
  }

  return {
    foundArticle,
    findArticleLoading,
    findByDocId,
  }
}

export const useArticleCollabActions = () => {
  const unsubscribe = ref<undefined | Fn>()
  const articleKindOneStore = useArticleKindOneStore()
  const { article, stageWidgetDatatableReload, articleBookmarksReload } = storeToRefs(articleKindOneStore)
  const multiTitleOneStore = useArticleMultiTitleOneStore()
  const { articleMultiTitles } = storeToRefs(multiTitleOneStore)

  const startArticleFieldsListeners = (room: CollabRoom) => {
    const { addCollabAnyDataChangeListener, unsubscribeCollabAnyDataChangeListener } = useCollabAnyDataChange(room)
    unsubscribe.value = unsubscribeCollabAnyDataChangeListener.value

    addCollabAnyDataChangeListener((field, data) => {
      if (field === 'articleBookmarks' && isNumber(data.value)) {
        articleBookmarksReload.value = data.value
        return
      }
      if (field.startsWith('multiTitleHeadline')) {
        const variant = field.replace('multiTitleHeadline:', '')
        return void articleMultiTitles.value.forEach((multiTitle) => {
          if (multiTitle.variant === variant && isString(data.value)) multiTitle.headline = data.value
        })
      }
      if (field.startsWith('multiTitlePerex')) {
        const variant = field.replace('multiTitlePerex:', '')
        return void articleMultiTitles.value.forEach((multiTitle) => {
          if (multiTitle.variant === variant && isString(data.value)) multiTitle.perex = data.value
        })
      }
      if (field.startsWith(COLLAB_FIELD_PREFIX_STAGES_CHANGE)) {
        if (!isArticleKindStandard(article.value)) return
        const collabData = data.value as ArticleStagesChangeCollabUpdate | null
        if (isNull(collabData)) return

        article.value.activeStages = collabData.data.activeStages
        article.value.completedStages = collabData.data.completedStages
        stageWidgetDatatableReload.value++
        return
      }
      if (field === COLLAB_FIELD_NAME_LOCKING_VERSION_CHANGE && isNumber(data.value)) {
        article.value.lockingVersion = data.value
      }
      if (field === 'bodyEditor') return
      objectSetValueByPath(article.value, field, data.value)
    })
  }

  const stopArticleFieldsListeners = () => {
    unsubscribe.value?.()
  }

  return {
    startArticleFieldsListeners,
    stopArticleFieldsListeners,
  }
}
