import { type FilterBag, type IntegerId, isUndefined, type Pagination, useAlerts } from '@anzusystems/common-admin'
import { ref } from 'vue'
import type { Keyword, KeywordValueObjectOption } from '@/types/cms/Keyword'
import {
  createKeyword,
  fetchKeyword,
  fetchKeywordList,
  fetchKeywordListByIds,
  updateKeyword,
} from '@/services/api/cms/keywordApi'
import useVuelidate from '@vuelidate/core'
import { storeToRefs } from 'pinia'
import { useKeywordOneStore } from '@/stores/cms/keywordStore'
import { useCachedKeywords } from '@/views/cms/keyword/composables/cachedKeywords'
import { useKeywordStatus } from '@/model/cms/valueObject/KeywordStatus'
import { KeywordValidationSymbol } from '@/views/cms/keyword/composables/keywordValidations'

const { showValidationError, showRecordWas, showErrorsDefault } = useAlerts()
const { addToCachedKeywords, fetchCachedKeywords } = useCachedKeywords()

const datatableHiddenColumns = ref<Array<string>>([])
const listLoading = ref(false)
const detailLoading = ref(false)
const saveButtonLoading = ref(false)

export const useKeywordListActions = () => {
  const listItems = ref<Keyword[]>([])

  const fetchList = async (pagination: Pagination, filterBag: FilterBag) => {
    listLoading.value = true
    try {
      listItems.value = await fetchKeywordList(pagination, filterBag)
      addToCachedKeywords(listItems.value.map((keyword) => keyword.currentKeyword))
      fetchCachedKeywords()
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      listLoading.value = false
    }
  }

  return {
    datatableHiddenColumns,
    listLoading,
    fetchList,
    listItems,
  }
}

export const useKeywordDetailActions = () => {
  const keywordOneStore = useKeywordOneStore()
  const { keyword } = storeToRefs(keywordOneStore)

  const fetchData = async (id: IntegerId) => {
    detailLoading.value = true
    try {
      const keywordRes = await fetchKeyword(id)
      keywordOneStore.setKeyword(keywordRes)
      addToCachedKeywords(keywordRes.currentKeyword)
      fetchCachedKeywords()
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      detailLoading.value = false
    }
  }

  return {
    keyword,
    detailLoading,
    fetchData,
    resetStore: keywordOneStore.reset,
  }
}

export const useKeywordCreateEditActions = () => {
  const v$ = useVuelidate({ $scope: KeywordValidationSymbol })
  const keywordOneStore = useKeywordOneStore()
  const { keyword, keywordUpdate, keywordCreate } = storeToRefs(keywordOneStore)

  const fetchData = async (id: IntegerId) => {
    detailLoading.value = true
    try {
      const keywordRes = await fetchKeyword(id)
      keywordOneStore.setKeyword(keywordRes)
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      detailLoading.value = false
    }
  }

  const onUpdate = async (onSuccess: ((keyword: Keyword) => void) | undefined = undefined) => {
    try {
      saveButtonLoading.value = true
      v$.value.$touch()
      if (v$.value.$invalid) {
        showValidationError()
        saveButtonLoading.value = false
        return
      }
      const res = await updateKeyword(keywordOneStore.keyword.id, keywordOneStore.keywordUpdate)
      showRecordWas('updated')
      if (!isUndefined(onSuccess)) onSuccess(res)
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      saveButtonLoading.value = false
    }
  }

  const onCreate = async (onSuccess: ((keyword: Keyword) => void) | undefined = undefined) => {
    try {
      saveButtonLoading.value = true
      v$.value.$touch()
      if (v$.value.$invalid) {
        showValidationError()
        saveButtonLoading.value = false
        return
      }
      const keywordRes = await createKeyword(keywordOneStore.keywordCreate)
      showRecordWas('created')
      if (!isUndefined(onSuccess)) onSuccess(keywordRes)
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      saveButtonLoading.value = false
    }
  }

  return {
    detailLoading,
    saveButtonLoading,
    keyword,
    keywordCreate,
    keywordUpdate,
    fetchData,
    onUpdate,
    onCreate,
    resetStore: keywordOneStore.reset,
  }
}

export const useKeywordSelectActions = () => {
  const { getKeywordStatusOption } = useKeywordStatus()

  const mapKeywordToKeywordValueObjectOption = (keyword: Keyword): KeywordValueObjectOption => ({
    title: keyword.name,
    value: keyword.id,
    color: getKeywordStatusOption(keyword.attributes.status)?.color,
    siteGroup: keyword.siteGroup,
    status: keyword.attributes.status,
    currentKeyword: keyword.currentKeyword,
  })

  const fetchItems = async (pagination: Pagination, filterBag: FilterBag) => {
    const keywords = await fetchKeywordList(pagination, filterBag)

    return <KeywordValueObjectOption[]>keywords.map((keyword: Keyword) => mapKeywordToKeywordValueObjectOption(keyword))
  }

  const fetchItemsByIds = async (ids: IntegerId[]) => {
    const keywords = await fetchKeywordListByIds(ids)

    return <KeywordValueObjectOption[]>keywords.map((keyword: Keyword) => mapKeywordToKeywordValueObjectOption(keyword))
  }

  return {
    mapKeywordToKeywordValueObjectOption,
    fetchItems,
    fetchItemsByIds,
  }
}
