import { SYSTEM_CMS } from '@/model/systems'
import type { DesignSettings } from '@/types/cms/DesignSettings'
import { DesignSettingsHeaderTemplateDefault, DesignSettingsThemeDefault } from '@/types/cms/DesignSettings'
import { dateTimeNow } from '@anzusystems/common-admin'

export function useDesignSettingsFactory() {
  const createDesignSettings = (): DesignSettings => {
    return {
      id: 0,
      texts: {
        title: '',
        description: '',
      },
      attributes: {
        theme: DesignSettingsThemeDefault,
        brickProjectName: '',
        favicon: '',
      },
      header: {
        template: DesignSettingsHeaderTemplateDefault,
        templateParams: {},
      },
      createdAt: dateTimeNow(),
      modifiedAt: dateTimeNow(),
      createdBy: null,
      modifiedBy: null,
      _resourceName: 'designSettings',
      _system: SYSTEM_CMS,
    }
  }

  return {
    createDesignSettings,
  }
}
