import type { ContentItemKind } from '@/types/cms/ContentItemKind/ContentItemKind'
import type { IntegerIdNullable } from '@anzusystems/common-admin'

export const LinkedListLayout = {
  ButtonsColoredLeft: 'buttonsColoredLeft',
  ButtonsLeft: 'buttonsLeft',
  ButtonsRight: 'buttonsRight',
  ButtonsLeftAndRight: 'buttonsLeftAndRight',
  InlineLinks: 'inlineLinks',
} as const
export const LinkedListLayoutDefault = LinkedListLayout.ButtonsColoredLeft
export type LinkedListLayoutType = (typeof LinkedListLayout)[keyof typeof LinkedListLayout]

export interface ContentItemKindLinkedList extends ContentItemKind {
  title: string
  linkedList: IntegerIdNullable
  layout: LinkedListLayoutType
  _resourceName: 'contentItemKindLinkedList'
}
