import useVuelidate from '@vuelidate/core'
import type { Ref } from 'vue'
import { computed } from 'vue'
import { useValidate } from '@anzusystems/common-admin'
import type { ArticleMultiTitle } from '@/types/cms/ArticleMultiTitle'
import { ArticleValidationSymbol } from '@/views/cms/article/composables/articleValidations'

export function useArticleMultiTitleValidation(multiTitle: Ref<ArticleMultiTitle>) {
  const { maxLength, minLength, required } = useValidate()

  const rules = computed(() => {
    return {
      multiTitle: {
        headline: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(255),
        },
        perex: {
          maxLength: maxLength(4096),
        },
      },
    }
  })
  const v$ = useVuelidate(rules, { multiTitle }, { $scope: ArticleValidationSymbol })

  return {
    v$,
  }
}
