<script setup lang="ts">
import { useAuth } from '@/composables/auth/auth'
import { useArticleMultiTitleFactory } from '@/model/cms/factory/ArticleMultiTitleFactory'
import { useBoxItemFilter } from '@/model/cms/filter/BoxItemFilter'
import { fetchBoxItemList } from '@/services/api/cms/boxItemApi'
import { useArticleKindOneStore } from '@/stores/cms/articleKindStore'
import { useArticleMultiTitleOneStore } from '@/stores/cms/articleMultiTitleStore'
import { isArticleKindStandard } from '@/types/cms/ArticleKind/ArticleKindStandard'
import {
  type ArticleMultiTitle,
  ArticleMultiTitleVariant,
  ArticleMultiTitleVariantDefault,
  type ArticleMultiTitleVariantType,
} from '@/types/cms/ArticleMultiTitle'
import type { BoxItem } from '@/types/cms/BoxItem'
import type { User } from '@/types/cms/User'
import type { CollabRoom } from '@/types/Collab'
import MultiTitleItemForm from '@/views/cms/articleMultiTitle/components/MultiTitleItemForm.vue'
import { useCachedUsers } from '@/views/cms/user/composables/cachedUsers'
import {
  ARow,
  ASortable,
  CollabStatus,
  type CollabStatusType,
  isUndefined,
  ROLE_SUPER_ADMIN,
  type SortableItem,
  useAlerts,
  useCollabHelpers,
  useCollabState,
  useCommonAdminCollabOptions,
  usePagination,
} from '@anzusystems/common-admin'
import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const props = withDefaults(
  defineProps<{
    collabRoom: CollabRoom
    collabStatus: CollabStatusType
    readonly: boolean
    isModerator: boolean
  }>(),
  {}
)

const articleKindOneStore = useArticleKindOneStore()
const { article } = storeToRefs(articleKindOneStore)
const articleMultiTitleOneStore = useArticleMultiTitleOneStore()
const { articleMultiTitles, selectedBoxItemsToOverrideTexts } = storeToRefs(articleMultiTitleOneStore)

const { showWarningT, showErrorT } = useAlerts()

const { createMultiTitle } = useArticleMultiTitleFactory()

const onAddMultiTitle = (beforeSortableItem: ArticleMultiTitle | null) => {
  const newMultiTitle = createMultiTitle(article.value.id)
  let variant: ArticleMultiTitleVariantType = ArticleMultiTitleVariantDefault
  if (beforeSortableItem) {
    const variantEntries = Object.entries(ArticleMultiTitleVariant)
    let i = 0
    for (const v of Object.values(ArticleMultiTitleVariant)) {
      if (v === beforeSortableItem.variant) {
        if (isUndefined(variantEntries[i + 1])) {
          return void showWarningT('cms.articleMultiTitle.tooltip.maxLimitReached')
        }
        variant = variantEntries[i + 1][1]
      }
      ++i
    }
  }
  newMultiTitle.position = (beforeSortableItem?.position ?? 0) + 1
  newMultiTitle.variant = variant
  if (variant === ArticleMultiTitleVariant.A) {
    newMultiTitle.headline = article.value.texts.headline
    newMultiTitle.perex = article.value.texts.perex
    newMultiTitle.inUse = true
  }
  articleMultiTitles.value.push(newMultiTitle)
}
const onDeleteMultiTitle = (item: SortableItem) => {
  if (item.raw.inUse) {
    return void showErrorT('cms.articleMultiTitle.tooltip.itemInUse')
  }
  articleMultiTitles.value.splice(item.index, 1)
}

const { showErrorsDefault } = useAlerts()

const relatedBoxItems = ref<BoxItem[]>([])
const relatedBoxItemsLoading = ref(false)

const fetchAllRelatedBoxItems = async (page: number = 1) => {
  const filter = useBoxItemFilter()
  const pagination = usePagination()
  pagination.page = page
  pagination.rowsPerPage = 20
  filter.articleDocId.model = article.value.docId
  const data = await fetchBoxItemList(pagination, filter)
  data.forEach((item) => {
    if (!relatedBoxItems.value.some((relatedItem) => relatedItem.id === item.id)) relatedBoxItems.value.push(item)
  })
  const hasMoreRelatedBoxItems = pagination.hasNextPage === null ? false : pagination.hasNextPage
  if (hasMoreRelatedBoxItems) await fetchAllRelatedBoxItems(page + 1)
}

const fetchRelatedBoxItems = async () => {
  relatedBoxItemsLoading.value = true
  try {
    await fetchAllRelatedBoxItems()
  } catch (error) {
    showErrorsDefault(error)
  } finally {
    relatedBoxItemsLoading.value = false
  }
}

const { createCollabFieldConfig } = useCollabHelpers()
const { cachedUsers } = useCachedUsers()
const { collabOptions } = useCommonAdminCollabOptions()

const { collabRoomInfoState } = useCollabState()

const collabActive = computed(() => {
  const roomInfo = collabRoomInfoState.get(props.collabRoom) ?? {
    name: props.collabRoom,
    moderator: null,
    users: [],
    status: CollabStatus.Inactive,
  }
  return collabOptions.value.enabled && roomInfo.status === CollabStatus.Active
})

const { t } = useI18n()

const { useCurrentUser } = useAuth()
const { currentUser: currentUserCms } = useCurrentUser<User>('cms')
</script>

<template>
  <ARow>
    <ASortable
      v-model="articleMultiTitles"
      :show-add-last-button="!collabActive && !readonly"
      :show-delete-button="!collabActive && !readonly"
      disable-draggable
      add-last-button-t="cms.articleKind.widget.multiTitle.add"
      class="a-sortable-widget--multi-title"
      @on-add-last="onAddMultiTitle"
      @on-delete="onDeleteMultiTitle"
    >
      <template #item="{ item }: { item: SortableItem<ArticleMultiTitle> }">
        <MultiTitleItemForm
          v-model="item.raw"
          :is-moderator="isModerator"
          :collab-room="collabRoom"
          :readonly="readonly"
          @changed="() => fetchRelatedBoxItems()"
        />
      </template>
    </ASortable>
  </ARow>
  <ARow>
    <VSwitch
      v-if="isArticleKindStandard(article)"
      v-model="article.flagsStandard.enableMultiTitleTest"
      class="d-none"
      :disabled="readonly"
      :label="t('cms.articleKind.model.flagsStandard.enableMultiTitleTest')"
      :collab="createCollabFieldConfig('flagStandard.enableMultiTitleTest', collabRoom, cachedUsers)"
    />
  </ARow>
  <ARow v-if="relatedBoxItems.length && currentUserCms">
    <h4>{{ t('cms.articleMultiTitle.meta.usageInBoxes') }}</h4>
    <VList
      v-model:selected="selectedBoxItemsToOverrideTexts"
      select-strategy="classic"
    >
      <VListItem
        v-for="relatedBoxItem in relatedBoxItems"
        :key="relatedBoxItem.id"
        :value="relatedBoxItem.id"
        :disabled="
          (!currentUserCms.roles.includes(ROLE_SUPER_ADMIN) &&
            relatedBoxItem.box &&
            !currentUserCms.editableBoxes.includes(relatedBoxItem.box)) ||
          readonly
        "
      >
        <template #title> {{ relatedBoxItem.boxTitle }} ({{ relatedBoxItem.id }}) </template>
        <template #append="{ isActive, select }">
          <VCheckboxBtn
            :model-value="isActive"
            color="primary"
            :readonly="readonly"
            :label="t('cms.articleMultiTitle.meta.useNewTexts')"
            @click="() => select(isActive)"
          />
        </template>
      </VListItem>
    </VList>
    <div
      v-if="relatedBoxItemsLoading"
      class="w-100 d-flex align-center justify-center"
    >
      <VProgressCircular indeterminate />
    </div>
  </ARow>
</template>

<style lang="scss">
@use 'sass:color';

$bg-color: #fff;
$border-color: lightgray;
$class-name-root: 'a-sortable-widget';
$border-color-hover: gray;

.a-sortable-widget--multi-title {
  .#{$class-name-root} {
    &__item {
      border-bottom: 1px solid $border-color !important;
      margin-bottom: 10px;
      padding-left: 8px;

      &:hover {
        border-color: $border-color-hover !important;
      }

      &:hover + & {
        border-top: 1px solid $border-color-hover;
      }
    }

    &__handle {
      display: none;
    }

    &__buttons {
      margin-left: 16px;
      padding-bottom: 6px;
    }
  }
}
</style>
