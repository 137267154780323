import { AuthorDiscriminatorDefault } from '@/model/cms/valueObject/AuthorDiscriminator'
import { AuthorResourceNameDefault } from '@/model/cms/valueObject/AuthorResourceName'
import type { AuthorKind } from '@/types/cms/AuthorKind/AuthorKind'
import { dateTimeNow } from '@anzusystems/common-admin'

export function useAuthorKindFactory() {
  const createAuthorKind = (): AuthorKind => {
    return {
      id: 0,
      discriminator: AuthorDiscriminatorDefault,
      superAuthor: null,
      slug: '',
      image: null,
      site: null,
      siteGroup: null,
      descriptionShort: '',
      notificationKey: '',
      enabled: false,
      settings: {
        enabledArticleAssign: false,
        enabledArticleShow: false,
        enabledFollow: false,
        enabledProfile: false,
        notificationFollowEnabled: false,
      },
      publicLinks: {
        email: '',
        facebook: '',
        instagram: '',
        linkedin: '',
        smeBlog: '',
        tiktok: '',
        twitter: '',
        web: '',
        youtube: '',
        wikipedia: '',
      },
      createdAt: dateTimeNow(),
      modifiedAt: dateTimeNow(),
      createdBy: null,
      modifiedBy: null,
      _resourceName: AuthorResourceNameDefault,
      _system: 'cms',
    }
  }

  return {
    createAuthorKind,
  }
}
