import type { DatetimeUTC } from '@anzusystems/common-admin'

export interface MetricPoint {
  id: string | undefined
  aggregationId: string | undefined
  name: string | undefined
  createdAt: DatetimeUTC
  updatedAt: DatetimeUTC | undefined
  value: number
  interval: number | undefined
}

export const MetricOrderDirection = {
  Asc: 'asc',
  Desc: 'desc',
} as const

export type MetricOrderDirectionType = (typeof MetricOrderDirection)[keyof typeof MetricOrderDirection]

export const MetricOrderBy = {
  CreatedAt: 'createdAt',
  Value: 'value',
} as const

export type MetricOrderByType = (typeof MetricOrderBy)[keyof typeof MetricOrderBy]

export const MetricGroupBy = {
  Day: 'day',
  Hour: 'hour',
  Minute: 'minute',
} as const

export type MetricGroupByType = (typeof MetricGroupBy)[keyof typeof MetricGroupBy]

export const EventOrderBy = {
  TriggeredAt: 'triggeredAt',
  CreatedAt: 'createdAt',
} as const

export type EventOrderByType = (typeof EventOrderBy)[keyof typeof EventOrderBy]