<script lang="ts" setup>
import { ADialogToolbar } from '@anzusystems/common-admin'
import { useI18n } from 'vue-i18n'
import { computed, ref } from 'vue'
import { useArticleKindEditActions } from '@/views/cms/article/composables/articleKindActions'
import { ArticleStatus } from '@/model/cms/valueObject/ArticleStatus'
import { ROUTE } from '@/router/routes'
import { useRouter } from 'vue-router'
import { useMainBarDialogsStore } from '@/views/cms/article/components/mainBarButtons/mainBarDialogsStore'
import { storeToRefs } from 'pinia'

const { t } = useI18n()
const router = useRouter()

const loading = ref(false)

const mainBarDialogsStore = useMainBarDialogsStore()
const { article } = storeToRefs(mainBarDialogsStore)

const { changeStatus } = useArticleKindEditActions()

const articleHasVersion = computed(() => {
  return article.value && article.value.articleVersions.length > 0
})

const onConfirm = async () => {
  if (!article.value) return
  loading.value = true
  await changeStatus(article.value, ArticleStatus.Draft)
  loading.value = false
  mainBarDialogsStore.closeDialog()
  router.push({ name: ROUTE.CMS.ARTICLE.EDIT, params: { id: article.value.id } })
}

const onClose = () => {
  mainBarDialogsStore.closeDialog()
}
</script>

<template>
  <VDialog
    :model-value="true"
    :width="500"
  >
    <VCard>
      <ADialogToolbar @on-cancel="onClose">
        {{ t('common.button.edit') }}
      </ADialogToolbar>
      <VCardText>
        <p v-if="articleHasVersion">
          {{ t('cms.articleKind.action.desc.readyWithVersion') }}
        </p>
        <p v-else>
          {{ t('cms.articleKind.action.desc.readyWithoutVersion') }}
        </p>
      </VCardText>
      <VCardActions>
        <VSpacer />
        <ABtnTertiary @click.stop="onClose">
          {{ t('common.button.cancel') }}
        </ABtnTertiary>
        <ABtnPrimary
          :loading="loading"
          @click.stop="onConfirm"
        >
          {{ t('common.button.confirm') }}
        </ABtnPrimary>
      </VCardActions>
    </VCard>
  </VDialog>
</template>
