<script setup lang="ts">
import {
  getCmsSiteGroupDamSelectLicences,
  getCmsSiteGroupDamUploadLicence,
} from '@/composables/cms/imageWidgetConfigProvider'
import { useArticleKindOneStore } from '@/stores/cms/articleKindStore'
import type { CollabRoom } from '@/types/Collab'
import { useCachedUsers } from '@/views/cms/user/composables/cachedUsers'
import { AImageWidget, AImageWidgetSimple, useCollabHelpers, type CollabStatusType } from '@anzusystems/common-admin'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'

withDefaults(
  defineProps<{
    collabRoom: CollabRoom
    collabStatus: CollabStatusType
    readonly: boolean
    isModerator: boolean
  }>(),
  {}
)

const { t } = useI18n()

const { createCollabFieldConfig } = useCollabHelpers()

const articleKindOneStore = useArticleKindOneStore()
const { article } = storeToRefs(articleKindOneStore)

const { cachedUsers } = useCachedUsers()
</script>

<template>
  <VRow>
    <VCol cols="6">
      <AImageWidgetSimple
        v-if="readonly"
        :model-value="article.listingImage"
        :label="t('cms.articleKind.model.listingImage')"
      />
      <AImageWidget
        v-else-if="article.siteGroup > 0"
        v-model="article.listingImage"
        :upload-licence="getCmsSiteGroupDamUploadLicence(article.siteGroup, 'image', 'default')"
        :select-licences="getCmsSiteGroupDamSelectLicences(article.siteGroup, 'image', 'default')"
        :label="t('cms.articleKind.model.listingImage')"
        queue-key="articleListingImage"
        :collab="createCollabFieldConfig('listingImage', collabRoom, cachedUsers)"
        :collab-status="collabStatus"
      />
    </VCol>
    <VCol cols="6">
      <AImageWidgetSimple
        v-if="readonly"
        :model-value="article.socialImage"
        :label="t('cms.articleKind.model.socialImage')"
      />
      <AImageWidget
        v-else-if="article.siteGroup > 0"
        v-model="article.socialImage"
        :upload-licence="getCmsSiteGroupDamUploadLicence(article.siteGroup, 'image', 'default')"
        :select-licences="getCmsSiteGroupDamSelectLicences(article.siteGroup, 'image', 'default')"
        :label="t('cms.articleKind.model.socialImage')"
        queue-key="articleSocialImage"
        :collab="createCollabFieldConfig('socialImage', collabRoom, cachedUsers)"
        :collab-status="collabStatus"
      />
    </VCol>
  </VRow>
</template>
