import { ACL } from '@/composables/auth/auth'
import { ROUTE } from '@/router/routes'
import type { RouteRecordRaw } from 'vue-router'

export const cmsPaywallRoutes: RouteRecordRaw[] = [
  {
    path: '/paywall',
    name: ROUTE.CMS.PAYWALL.LIST,
    component: () => import('@/views/cms/paywall/PaywallListView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/paywall/create',
    name: ROUTE.CMS.PAYWALL.CREATE,
    component: () => import('@/views/cms/paywall/PaywallCreateView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_PAYWALL_CREATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/paywall/:id(\\d+)/edit',
    name: ROUTE.CMS.PAYWALL.EDIT,
    component: () => import('@/views/cms/paywall/PaywallEditView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_PAYWALL_READ, ACL.CMS_PAYWALL_UPDATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/paywall/:id(\\d+)',
    name: ROUTE.CMS.PAYWALL.DETAIL,
    component: () => import('@/views/cms/paywall/PaywallDetailView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_PAYWALL_READ],
      layout: 'AppLayoutDrawer',
    },
  },
]
