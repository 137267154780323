import { useContentItemKindFactory } from '@/model/cms/factory/ContentItemKind/ContentItemKindFactory'
import { ContentItemDiscriminator } from '@/types/cms/ContentItemKind/ContentItemKind'
import type { ContentItemKindRempBanner } from '@/types/cms/ContentItemKind/ContentItemKindRempBanner'

const { createAbstractContentItemKind } = useContentItemKindFactory()

export function useContentItemKindRempBannerFactory() {
  const create = (): ContentItemKindRempBanner => {
    return {
      ...createAbstractContentItemKind(),
      name: '',
      discriminator: ContentItemDiscriminator.RempBanner,
      _resourceName: 'contentItemKindRempBanner',
    }
  }

  return {
    create,
  }
}
