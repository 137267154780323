import { LanguageDefault } from '@/model/cms/valueObject/Language'
import { SYSTEM_CMS } from '@/model/systems'
import type { Site } from '@/types/cms/Site'
import { dateTimeNow } from '@anzusystems/common-admin'

export function useSiteFactory() {
  const createDefault = (): Site => {
    return {
      id: 0,
      name: '',
      language: LanguageDefault,
      domain: '',
      siteGroup: null,
      linkedList: null,
      secondaryLinkedList: null,
      siteMapLinkedList: null,
      designSettings: null,
      advertSettings: null,
      authorLayoutTemplate: null,
      galleryLayoutTemplate: null,
      forumLayoutTemplate: null,
      createdAt: dateTimeNow(),
      modifiedAt: dateTimeNow(),
      createdBy: null,
      modifiedBy: null,
      primaryNewsletter: null,
      secondaryNewsletter: null,
      searchPage: null,
      mainPage: null,
      seoImage: null,
      seo: {
        postfix: '',
        globalMetaTags: [],
        articleMetaTags: [],
        robots: '',
      },
      analytics: {
        rempPropertyToken: '',
        gtmId: '',
        gemiusId: '',
        gemiusStreamPlayerId: '',
        gemiusStreamId: '',
      },
      epilogue: '',
      settings: {
        overrideParentContentLockSettings: false,
        allowedFreeRss: false,
        lockAfterPercentage: 0.0,
      },
      rssTexts: {
        title: '',
        description: '',
        webMaster: '',
      },
      _resourceName: 'site',
      _system: SYSTEM_CMS,
    }
  }

  return {
    createDefault,
  }
}
