import type { DocId } from '@anzusystems/common-admin'
import { fetchMetrics, fetchObjectEvents } from '@/services/api/owl/owlApi'
import { envConfig } from '@/services/EnvConfigService'
import type { ChartLinePointType } from '@/types/highcharts/Highcharts'
import type { MetricGroupByType, MetricPoint } from '@/types/owl/Metric'
import { EventOrderBy, MetricGroupBy, MetricOrderBy, MetricOrderDirection } from '@/types/owl/Metric'
import type { ArticleTimeMetrics } from '@/types/owl/ArticleTimeMetrics'
import type { ArticleSumMetrics } from '@/types/owl/ArticleSumMetrics'
import { useAlerts } from '@anzusystems/common-admin'

export const useOwl = () => {
  const { showErrorsDefault } = useAlerts()

  function convertMetricPointsToChartPoints(
    metricPoints: MetricPoint[],
    groupBy: MetricGroupByType,
    since: Date | undefined
  ): ChartLinePointType[] {
    const result: ChartLinePointType[] = []
    for (const metricPoint of metricPoints) {
      result.push([new Date(metricPoint.createdAt).getTime(), metricPoint.value])
    }

    if (result.length <= 1) {
      return result
    }

    const start = since || new Date(result[0][0])
    let step = 60 * 1000
    switch (groupBy) {
      case MetricGroupBy.Day:
        step = 24 * 3600 * 1000
        start.setHours(0, 0, 0, 0)
        break
      case MetricGroupBy.Hour:
        step = 3600 * 1000
        start.setMinutes(0, 0, 0)
        break
      case MetricGroupBy.Minute:
        step = 60 * 1000
        start.setSeconds(0, 0)
        break
    }

    const end =  new Date()

    return fillMissingIntervals(result, start, end, step)
  }

  function getFirstMetricPointValue(metricPoints: MetricPoint[]): number {
    if (metricPoints.length <= 0) {
      return 0
    }

    return metricPoints[0].value
  }

  function fillMissingIntervals(
    data: ChartLinePointType[],
    start: Date,
    end: Date,
    step: number
  ): ChartLinePointType[] {
    const result: ChartLinePointType[] = []
    let currentTime = start.getTime()
    const endTime = end.getTime()

    while (currentTime <= endTime) {
      const found = data.find((d) => d[0] === currentTime)
      if (found) {
        result.push(found)
      } else {
        result.push([currentTime, 0])
      }

      currentTime += step
    }

    return result
  }

  const loadArticleTimeMetrics =  async(
    articleOwlId: DocId,
    groupBy: MetricGroupByType,
    since: Date | undefined = undefined
  ): Promise<ArticleTimeMetrics|undefined> => {
    const objectEvents = fetchObjectEvents(articleOwlId, EventOrderBy.TriggeredAt, MetricOrderDirection.Asc)

    const fetchArticleView = fetchMetrics(
      envConfig.owl.timeAggregations.articleView,
      articleOwlId,
      MetricOrderBy.CreatedAt,
      MetricOrderDirection.Asc,
      groupBy,
      since
    )
    const fetchArticleQualityView = fetchMetrics(
      envConfig.owl.timeAggregations.articleQualityView,
      articleOwlId,
      MetricOrderBy.CreatedAt,
      MetricOrderDirection.Asc,
      groupBy,
      since
    )
    const fetchArticleTimeSpent = fetchMetrics(
      envConfig.owl.timeAggregations.articleTimeSpent,
      articleOwlId,
      MetricOrderBy.CreatedAt,
      MetricOrderDirection.Asc,
      groupBy,
      since
    )

    try {
      const [
        objectEventsResult,
        articleViewResult,
        articleQualityViewResult,
        articleTimeSpentResult
      ] = await Promise.all(
        [
          objectEvents,
          fetchArticleView, fetchArticleQualityView,
          fetchArticleTimeSpent
        ]
      )
      return <ArticleTimeMetrics>{
        objectEvents: objectEventsResult,
        viewPoints: convertMetricPointsToChartPoints(articleViewResult, groupBy, since),
        qualityViewPoints: convertMetricPointsToChartPoints(articleQualityViewResult, groupBy, since),
        timeSpentPoints: convertMetricPointsToChartPoints(articleTimeSpentResult, groupBy, since),
      }
    } catch (error) {
      showErrorsDefault(error)
    }
  }

  const loadArticleSumMetrics =  async (articleOwlId: DocId): Promise<ArticleSumMetrics|undefined> => {
    const fetchArticleView = fetchMetrics(envConfig.owl.sumAggregations.all.articleView, articleOwlId)
    const fetchArticleQualityView = fetchMetrics(envConfig.owl.sumAggregations.all.articleQualityView, articleOwlId)
    const fetchArticleTimeSpent = fetchMetrics(envConfig.owl.sumAggregations.all.articleTimeSpent, articleOwlId)
    const fetchAppArticleView = fetchMetrics(envConfig.owl.sumAggregations.app.articleView, articleOwlId)
    const fetchAppArticleQualityView = fetchMetrics(envConfig.owl.sumAggregations.app.articleQualityView, articleOwlId)
    const fetchAppArticleTimeSpent = fetchMetrics(envConfig.owl.sumAggregations.app.articleTimeSpent, articleOwlId)

    try {
      const [
        articleViewResult,
        articleQualityViewResult,
        articleTimeSpentResult,
        appArticleViewResult,
        appArticleQualityViewResult,
        appArticleTimeSpentResult
      ] = await Promise.all([
        fetchArticleView,
        fetchArticleQualityView,
        fetchArticleTimeSpent,
        fetchAppArticleView,
        fetchAppArticleQualityView,
        fetchAppArticleTimeSpent,
      ])
      return <ArticleSumMetrics>{
        all: {
          viewCount: getFirstMetricPointValue(articleViewResult),
          qualityViewCount: getFirstMetricPointValue(articleQualityViewResult),
          timeSpentCount: getFirstMetricPointValue(articleTimeSpentResult),
        },
        app: {
          viewCount: getFirstMetricPointValue(appArticleViewResult),
          qualityViewCount: getFirstMetricPointValue(appArticleQualityViewResult),
          timeSpentCount: getFirstMetricPointValue(appArticleTimeSpentResult),
        },
      }
    } catch (error) {
      showErrorsDefault(error)
    }
  }

  return {
    loadArticleSumMetrics,
    loadArticleTimeMetrics,
  }
}
