import { ACL, useAuth } from '@/composables/auth/auth'
import { ArticleStatus } from '@/model/cms/valueObject/ArticleStatus'
import { ROUTE } from '@/router/routes'
import type { ArticleKind } from '@/types/cms/ArticleKind/ArticleKind'
import { MainBarDialog } from '@/views/cms/article/components/mainBarButtons/mainBarButtons'
import { useMainBarDialogsStore } from '@/views/cms/article/components/mainBarButtons/mainBarDialogsStore'
import { computed } from 'vue'
import { useRouter } from 'vue-router'

export const useMainBarDialogActions = () => {
  const mainBarDialogsStore = useMainBarDialogsStore()
  const router = useRouter()
  const { canForAll } = useAuth()

  const canEdit = computed(() => {
    return canForAll([ACL.CMS_ARTICLE_READ, ACL.CMS_ARTICLE_UPDATE])
  })

  const onRowClick = (item: ArticleKind) => {
    if (!item.id) return
    mainBarDialogsStore.article = item
    if (item.status === ArticleStatus.Draft) {
      router.push({ name: canEdit.value ? ROUTE.CMS.ARTICLE.EDIT : ROUTE.CMS.ARTICLE.DETAIL, params: { id: item.id } })
      return
    }
    if (item.status === ArticleStatus.Ready) {
      mainBarDialogsStore.openDialog(MainBarDialog.EditDialogReady)
      return
    }
    if (item.status === ArticleStatus.Published) {
      mainBarDialogsStore.openDialog(MainBarDialog.EditDialogPublished)
      return
    }
  }

  const onWithdraw = (item: ArticleKind) => {
    if (!item.id) return
    mainBarDialogsStore.article = item
    if (item.status === ArticleStatus.Published) {
      mainBarDialogsStore.openDialog(MainBarDialog.Withdraw)
    }
  }

  return {
    onRowClick,
    onWithdraw,
  }
}
