<script lang="ts" setup>
import type { UserMinimal } from '@/types/cms/User'
import { useCachedUsers } from '@/views/cms/user/composables/cachedUsers'
import { AAnzuUserAvatar, COMMON_CONFIG, type IntegerId, isNull, isUndefined } from '@anzusystems/common-admin'
import { computed, shallowRef, watch } from 'vue'

const props = withDefaults(
  defineProps<{
    id: null | undefined | IntegerId
    minimal?: boolean
  }>(),
  {
    minimal: false,
  }
)

// const router = useRouter()
const cached = shallowRef<undefined | UserMinimal>(undefined)
const loaded = shallowRef<boolean>(false)

const { getCachedUser } = useCachedUsers()

const item = computed(() => {
  return getCachedUser(props.id)
})

const text = computed(() => {
  if (cached.value) {
    return cached.value.person.fullName.length ? cached.value.person.fullName : cached.value.email.split('@')[0]
  }
  return ''
})

const onClick = () => {
  // router.push({ name: ROUTE.CMS.USER.DETAIL, params: { id: props.id } })
}

watch(
  item,
  async (newValue) => {
    if (loaded.value) return
    if (isUndefined(newValue) || newValue._loaded === false) return
    cached.value = newValue
    loaded.value = true
  },
  { immediate: true }
)
</script>

<template>
  <div
    v-if="minimal"
    class="d-inline-flex gc-1"
  >
    <span v-if="isNull(id) || isUndefined(id)" />
    <AAnzuUserAvatar
      v-else-if="loaded"
      :user="cached ?? undefined"
      container-class=""
      :size="20"
      :tooltip="text"
    />
    <VProgressCircular
      v-else
      :size="12"
      :width="2"
      indeterminate
      class="ml-1"
    />
  </div>
  <div
    v-else
    class="d-inline-flex"
  >
    <span v-if="isNull(id) || isUndefined(id)">-</span>
    <VChip
      v-else
      class="pl-1"
      size="small"
      :append-icon="COMMON_CONFIG.CHIP.ICON.LINK"
      @click.stop="onClick"
    >
      <template #prepend>
        <AAnzuUserAvatar
          v-if="loaded"
          :user="cached ?? undefined"
          container-class="mr-1"
          :size="20"
        />
      </template>
      {{ text }}
      <VProgressCircular
        v-if="!loaded"
        :size="12"
        :width="2"
        indeterminate
        class="ml-1"
      />
    </VChip>
  </div>
</template>
