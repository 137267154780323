import useVuelidate from '@vuelidate/core'
import type { Ref } from 'vue'
import { useValidate } from '@anzusystems/common-admin'
import type { ArticleIntention } from '@/types/cms/Task'

export const ArticleIntentionValidationSymbol = Symbol.for('anzu:cms:article-intention-validation-scope')

export function useArticleIntentionValidation(articleIntention: Ref<ArticleIntention>) {
  const { maxLength, minLength,  required } = useValidate()

  const rules = {
    articleIntention: {
      name: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(256),
      },
    },
  }
  const v$ = useVuelidate(rules, { articleIntention }, { $scope: ArticleIntentionValidationSymbol })

  return {
    v$,
  }
}
