import { SYSTEM_CMS } from '@/model/systems'
import { ENTITY } from '@/services/api/cms/stageApi'
import { makeFilterHelper } from '@anzusystems/common-admin'
import { reactive } from 'vue'

const makeFilter = makeFilterHelper(SYSTEM_CMS, ENTITY)

const filter = reactive({
  id: {
    ...makeFilter({ name: 'id' }),
  },
  docId: {
    ...makeFilter({ name: 'docId' }),
  },
  stage: {
    ...makeFilter({ name: 'stage' }),
  },
  desk: {
    ...makeFilter({ name: 'desk', variant: 'in' }),
  },
  status: {
    ...makeFilter({ name: 'status' }),
  },
  priority: {
    ...makeFilter({ name: 'priority' }),
  },
})

export function useArticleStageListFilter() {
  return filter
}

const articleFilter = reactive({
  docId: {
    ...makeFilter({ name: 'docId', field: 'articleDocId' }),
  },
})

export function useArticleArticleStageFilter() {
  return articleFilter
}
