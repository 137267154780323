import type { ContentItemKind } from '@/types/cms/ContentItemKind/ContentItemKind'

export const AggregationLayout = {
  Newest: 'newest',
  Author: 'author',
  Tabs: 'tabs',
} as const
export const AggregationLayoutDefault = AggregationLayout.Newest
export type AggregationLayoutType = (typeof AggregationLayout)[keyof typeof AggregationLayout]

export interface ContentItemKindAggregation extends ContentItemKind {
  title: string
  url: string
  layout: AggregationLayoutType
  contentItems: ContentItemKind[]
  _resourceName: 'contentItemKindAggregation'
}
