import { ACL } from '@/composables/auth/auth'
import { ROUTE } from '@/router/routes'
import type { RouteRecordRaw } from 'vue-router'

export const cmsPersonPageRoutes: RouteRecordRaw[] = [
  {
    path: '/person-page',
    name: ROUTE.CMS.PERSON_PAGE.LIST,
    component: () => import('@/views/cms/personPage/PersonPageListView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/person-page/create',
    name: ROUTE.CMS.PERSON_PAGE.CREATE,
    component: () => import('@/views/cms/personPage/PersonPageCreateView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_PERSON_PAGE_CREATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/person-page/:id(\\d+)/edit',
    name: ROUTE.CMS.PERSON_PAGE.EDIT,
    component: () => import('@/views/cms/personPage/PersonPageEditView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_PERSON_PAGE_READ, ACL.CMS_PERSON_PAGE_UPDATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/person-page/:id(\\d+)',
    name: ROUTE.CMS.PERSON_PAGE.DETAIL,
    component: () => import('@/views/cms/personPage/PersonPageDetailView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_PERSON_PAGE_READ],
      layout: 'AppLayoutDrawer',
    },
  },
]
