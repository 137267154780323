import { ACL } from '@/composables/auth/auth'
import { ROUTE } from '@/router/routes'
import type { RouteRecordRaw } from 'vue-router'

export const cmsOrganizationRoutes: RouteRecordRaw[] = [
  {
    path: '/organization',
    name: ROUTE.CMS.ORGANIZATION.LIST,
    component: () => import('@/views/cms/organization/OrganizationListView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/organization/create',
    name: ROUTE.CMS.ORGANIZATION.CREATE,
    component: () => import('@/views/cms/organization/OrganizationCreateView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_ORGANIZATION_CREATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/organization/:id(\\d+)/edit',
    name: ROUTE.CMS.ORGANIZATION.EDIT,
    component: () => import('@/views/cms/organization/OrganizationEditView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_ORGANIZATION_READ, ACL.CMS_ORGANIZATION_UPDATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/organization/:id(\\d+)',
    name: ROUTE.CMS.ORGANIZATION.DETAIL,
    component: () => import('@/views/cms/organization/OrganizationDetailView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_ORGANIZATION_READ],
      layout: 'AppLayoutDrawer',
    },
  },
]
