import { acceptHMRUpdate, defineStore } from 'pinia'
import { computed, ref } from 'vue'
import type { AppNotification } from '@/composables/appNotification/appNotificationEventBus'
import { AppNotificationMessageType } from '@/composables/appNotification/appNotificationEventBus'

export const SUPPORTED_TYPES = [AppNotificationMessageType.Job, AppNotificationMessageType.Task]

export const useAppNotificationStore = defineStore('appNotificationStore', () => {
  const appNotificationSidebar = ref(false)
  const notifications = ref<AppNotification[]>([])
  const unreadCountCached = ref(0)
  const mode = ref<'all' | 'unread'>('all')
  const showMore = ref(false)
  const loading = ref({ all: false, more: false })

  const unreadCountStored = computed(() => {
    return notifications.value.reduce((count, message) => {
      return count + (message.read ? 0 : 1)
    }, 0)
  })

  const unreadCount = computed(() => {
    if (unreadCountStored.value > unreadCountCached.value) return unreadCountStored.value
    return unreadCountCached.value
  })

  function addNotification(notification: AppNotification) {
    if (!SUPPORTED_TYPES.includes(notification.message.type as any)) return
    const foundIndex = notifications.value.findIndex((item) => item.id === notification.id)
    if (foundIndex >= 0) {
      notifications.value.splice(foundIndex, 1, notification)
      return
    }
    notifications.value.unshift(notification)
  }

  function markAsRead(ids: string[]) {
    notifications.value.forEach((notification) => {
      if (ids.includes(notification.id)) {
        notification.read = true
      }
    })
  }

  function reset() {
    appNotificationSidebar.value = false
    notifications.value = []
    unreadCountCached.value = 0
    mode.value = 'all'
    showMore.value = false
    loading.value.all = false
    loading.value.more = false
  }

  return {
    appNotificationSidebar,
    notifications,
    unreadCountCached,
    unreadCount,
    mode,
    showMore,
    loading,
    addNotification,
    markAsRead,
    reset,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAppNotificationStore, import.meta.hot))
}
