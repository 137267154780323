import { ref } from 'vue'
import type { ValueObjectOption } from '@anzusystems/common-admin'
import { useI18n } from 'vue-i18n'

const ChartInterval = {
  Day: '24h',
  SevenDays: '7d',
  All: 'all',
} as const

export type ChartIntervalType = (typeof ChartInterval)[keyof typeof ChartInterval]
export const ChartIntervalDefault = ChartInterval.Day
export const ChartIntervalDefaultForMiddleAgeArticle = ChartInterval.SevenDays
export const ChartIntervalDefaultForOldArticle = ChartInterval.All

export function useChartInterval() {
  const { t } = useI18n()

  const chartIntervalOptions = ref<ValueObjectOption<ChartIntervalType>[]>([
    {
      value: ChartInterval.Day,
      title: t('cms.articleKind.widget.basicStatistics.chartInterval.day'),
    },
    {
      value: ChartInterval.SevenDays,
      title: t('cms.articleKind.widget.basicStatistics.chartInterval.sevenDays'),
    },
    {
      value: ChartInterval.All,
      title: t('cms.articleKind.widget.basicStatistics.chartInterval.all'),
    },
  ])

  const getChartIntervalOption = (value: ChartIntervalType) => {
    return chartIntervalOptions.value.find((item) => item.value === value)
  }

  return {
    chartIntervalOptions,
    getChartIntervalOption,
  }
}
