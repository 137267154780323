import { useContentItemKindFactory } from '@/model/cms/factory/ContentItemKind/ContentItemKindFactory'
import { ContentItemDiscriminator } from '@/types/cms/ContentItemKind/ContentItemKind'
import type { ContentItemKindAggregation } from '@/types/cms/ContentItemKind/ContentItemKindAggregation'
import { AggregationLayoutDefault } from '@/types/cms/ContentItemKind/ContentItemKindAggregation'

const { createAbstractContentItemKind } = useContentItemKindFactory()

export function useContentItemKindAggregationFactory() {
  const create = (): ContentItemKindAggregation => {
    return {
      ...createAbstractContentItemKind(),
      title: '',
      url: '',
      layout: AggregationLayoutDefault,
      contentItems: [],
      discriminator: ContentItemDiscriminator.Aggregation,
      _resourceName: 'contentItemKindAggregation',
    }
  }

  return {
    create,
  }
}
