import { ACL } from '@/composables/auth/auth'
import { ROUTE } from '@/router/routes'
import type { RouteRecordRaw } from 'vue-router'

export const cmsPersonRoutes: RouteRecordRaw[] = [
  {
    path: '/person',
    name: ROUTE.CMS.PERSON.LIST,
    component: () => import('@/views/cms/person/PersonListView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/person/create',
    name: ROUTE.CMS.PERSON.CREATE,
    component: () => import('@/views/cms/person/PersonCreateView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_PERSON_CREATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/person/:id(\\d+)/edit',
    name: ROUTE.CMS.PERSON.EDIT,
    component: () => import('@/views/cms/person/PersonEditView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_PERSON_READ, ACL.CMS_PERSON_UPDATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/person/:id(\\d+)',
    name: ROUTE.CMS.PERSON.DETAIL,
    component: () => import('@/views/cms/person/PersonDetailView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_PERSON_READ],
      layout: 'AppLayoutDrawer',
    },
  },
]
