import { makeFilterHelper } from '@anzusystems/common-admin'
import { reactive, ref } from 'vue'

const datatableHiddenColumns = ref([
  'id',
  'docId',
  'texts.seoTitle',
  'dates.publicPublishedAt',
  'dates.expireAt',
  'dates.publishedAt',
  'createdAt',
  'modifiedAt',
])

const makeFilter = makeFilterHelper('cms', 'articleKind')

const filter = reactive({
  _elastic: {
    ...makeFilter({ exclude: true }),
  },
  id: {
    ...makeFilter({ name: 'id' }),
  },
  docId: {
    ...makeFilter({ name: 'docId' }),
  },
  text: {
    ...makeFilter({ name: 'text' }),
  },
  title: {
    ...makeFilter({ name: 'title' }),
  },
  url: {
    ...makeFilter({ name: 'url' }),
  },
  siteGroup: {
    ...makeFilter({ name: 'siteGroup', field: 'siteGroupId', default: null }),
  },
  site: {
    ...makeFilter({ name: 'site', field: 'siteIds', default: [] }),
  },
  rubric: {
    ...makeFilter({ name: 'rubrics', field: 'rubricIds', default: [] }),
  },
  linkedList: {
    ...makeFilter({ name: 'linkedList', field: 'linkedListIds', default: [] }),
  },
  discriminator: {
    ...makeFilter({ name: 'discriminator', clearable: false }),
  },
  publicPublishedAtFrom: {
    ...makeFilter({ name: 'publicPublishedAtFrom' }),
  },
  publicPublishedAtUntil: {
    ...makeFilter({ name: 'publicPublishedAtUntil' }),
  },
  modifiedAtFrom: {
    ...makeFilter({ name: 'modifiedAtFrom' }),
  },
  modifiedAtUntil: {
    ...makeFilter({ name: 'modifiedAtUntil' }),
  },
  owners: {
    ...makeFilter({ name: 'owners', field: 'ownerIds', default: [] }),
  },
  keywords: {
    ...makeFilter({ name: 'keywords', field: 'keywordIds', default: [] }),
  },
  articleAuthors: {
    ...makeFilter({ name: 'articleAuthors', field: 'authorIds', default: [] }),
  },
})

export function useArticleSelectStore() {
  return {
    filter,
    datatableHiddenColumns,
  }
}
