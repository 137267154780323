<script lang="ts" setup>
import { ROUTE } from '@/router/routes'
import type { AuthorMinimal } from '@/types/cms/AuthorKind/AuthorKind'
import { useCachedAuthors } from '@/views/cms/author/composables/cachedAuthors'
import type { IntegerId } from '@anzusystems/common-admin'
import { ACachedChip, isUndefined } from '@anzusystems/common-admin'

const props = withDefaults(
  defineProps<{
    id: null | IntegerId | undefined
    showDescription?: boolean
    textOnly?: boolean
  }>(),
  {
    showDescription: false,
    textOnly: false,
  }
)

const { getCachedAuthor } = useCachedAuthors()

const customTitleFn = (
      cachedItem: any,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      defaultTitle: string,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      displayTextPath: string,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      fallbackIdText: boolean
    ) => {
      const data = cachedItem as AuthorMinimal | undefined
      if (isUndefined(data)) return props.id + ''
      if (props.showDescription && data.description.length > 0) {
        return `${data.displayName} (${data.description})`
      }
      return data.displayName
    }
</script>

<template>
  <ACachedChip
    :id="id"
    :get-cached-fn="getCachedAuthor"
    :route="ROUTE.CMS.AUTHOR.DETAIL"
    :text-only="textOnly"
    display-text-path="displayName"
    wrap-text
    :custom-title-fn="customTitleFn"
  />
</template>
