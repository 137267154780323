<script lang="ts" setup>
import { useAuth } from '@/composables/auth/auth'
import { FILTER_KEY } from '@/composables/system/filterStoreKey'
import { useInboxFilter } from '@/model/cms/filter/InboxFilter'
import type { User } from '@/types/cms/User'
import { useInboxSelectActions } from '@/views/cms/inbox/composables/inboxActions'
import { AFormRemoteAutocomplete, isArray, isNull, isUndefined, type IntegerId } from '@anzusystems/common-admin'
import { onMounted, ref, watch } from 'vue'

const props = withDefaults(
  defineProps<{
    label?: string | undefined
    required?: boolean | undefined
    multiple?: boolean
    store?: boolean
    disableInitFetch?: boolean
  }>(),
  {
    label: undefined,
    required: undefined,
    multiple: false,
    store: false,
    disableInitFetch: false,
  }
)
const modelValue = defineModel<string | IntegerId | string[] | IntegerId[] | null | object>({ required: true })
const ready = ref(false)

const { fetchItems, fetchItemsByIds } = useInboxSelectActions()
const innerFilter = useInboxFilter()

const storeData = (storeId: string) => {
  if (!props.store || !localStorage) return
  localStorage.setItem(storeId, JSON.stringify(modelValue.value))
}

const loadStoredData = (storeId: string) => {
  if (!props.store || !localStorage) {
    ready.value = true
    return
  }
  const stored = localStorage.getItem(storeId)
  if (!stored) {
    ready.value = true
    return
  }
  modelValue.value = JSON.parse(stored)
  ready.value = true
}

const clearStoredData = (storeId: string) => {
  if (!props.store || !localStorage) return
  localStorage.removeItem(storeId)
}

watch(modelValue, (newValue) => {
  if (isNull(newValue) || (isArray(newValue) && newValue.length === 0)) {
    clearStoredData(FILTER_KEY.inboxAutocomplete)
    return
  }
  storeData(FILTER_KEY.inboxAutocomplete)
})

const { useCurrentUser } = useAuth()
const { currentUser, isSuperAdmin } = useCurrentUser<User>('cms')

const initAllowed = () => {
  if (isUndefined(currentUser.value)) return
  if (!isSuperAdmin.value) {
    innerFilter.id.model = currentUser.value.allowedInboxes
  }
}

onMounted(() => {
  initAllowed()
  loadStoredData(FILTER_KEY.inboxAutocomplete)
})
</script>

<template>
  <AFormRemoteAutocomplete
    v-if="ready"
    v-model="modelValue"
    :required="required"
    :label="label"
    :fetch-items="fetchItems"
    :fetch-items-by-ids="fetchItemsByIds"
    :inner-filter="innerFilter"
    :multiple="multiple"
    :disable-init-fetch="disableInitFetch"
  />
</template>
