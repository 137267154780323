import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import type { ValueObjectOption } from '@anzusystems/common-admin'

export const RouteStatus = {
  Disabled: 'disabled',
  Active: 'active',
  Reserved: 'reserved',
} as const

export const RouteStatusDefault = RouteStatus.Active
export type RouteStatusType = (typeof RouteStatus)[keyof typeof RouteStatus]

export function useRouteStatus() {
  const { t } = useI18n()

  const routeStatusOptions = ref<ValueObjectOption<RouteStatusType>[]>([
    {
      value: RouteStatus.Active,
      title: t('cms.routeKind.routeStatus.Active'),
      color: 'default',
    },
    {
      value: RouteStatus.Disabled,
      title: t('cms.routeKind.routeStatus.Disabled'),
      color: 'success',
    },
    {
      value: RouteStatus.Reserved,
      title: t('cms.routeKind.routeStatus.Reserved'),
      color: 'success',
    }
  ])

  const getRouteStatusOption = (value: RouteStatusType) => {
    return routeStatusOptions.value.find((item) => item.value === value)
  }

  return {
    routeStatusOptions,
    getRouteStatusOption,
  }
}
