<script setup lang="ts">
import CustomForm from '@/components/cms/CustomForm.vue'
import { CustomFormResourceName } from '@/model/common/valueObject/CustomFormResourceNames'
import { useArticleKindOneStore } from '@/stores/cms/articleKindStore'
import type { CollabRoom } from '@/types/Collab'
import { useArticleKindEditActions } from '@/views/cms/article/composables/articleKindActions'
import { ArticleCustomDataValidationScope } from '@/views/cms/article/composables/articleValidations'
import { isNull, type CollabStatusType } from '@anzusystems/common-admin'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'

withDefaults(
  defineProps<{
    collabRoom: CollabRoom
    collabStatus: CollabStatusType
    readonly: boolean
    isModerator: boolean
  }>(),
  {}
)

const articleKindOneStore = useArticleKindOneStore()
const { article } = storeToRefs(articleKindOneStore)

const customFormHasElements = ref(false)

const { customFormInstance } = useArticleKindEditActions()
</script>

<template>
  <CustomForm
    v-if="!isNull(article.siteGroup)"
    ref="customFormInstance"
    v-model="article.customData"
    v-model:has-elements="customFormHasElements"
    :readonly="readonly"
    :resource-name="CustomFormResourceName.ArticleKind"
    :site-group="article.siteGroup"
    :validation-scope="ArticleCustomDataValidationScope"
  />
</template>
