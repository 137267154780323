import { useDocumentFactory } from '@/components/anzutap/factory/DocumentFactory'
import { useTargetPositionFactory } from '@/model/cms/factory/TargetPositionFactory'
import { SYSTEM_CMS } from '@/model/systems'
import { ArticleWebAdvertTypeDefault, type LayoutTemplate, type LayoutTemplateTargetPosition } from '@/types/cms/LayoutTemplate'
import { WebEntityTypeDefault } from '@/types/cms/TargetPosition'
import { dateTimeNow } from '@anzusystems/common-admin'

export function useLayoutTemplateFactory() {
  const { createTargetPosition } = useTargetPositionFactory()
  const { createEmptyDocument } = useDocumentFactory()

  const createLayoutTemplate = (): LayoutTemplate => {
    return {
      id: 0,
      texts: {
        title: '',
        description: '',
        helpBody: createEmptyDocument(),
      },
      attributes: {
        webEntityType: WebEntityTypeDefault,
        articleWebAdvertType: ArticleWebAdvertTypeDefault,
        default: false,
        wideForm: false,
      },
      image: null,
      layoutTemplateTargetPositions: [],
      createdAt: dateTimeNow(),
      modifiedAt: dateTimeNow(),
      createdBy: null,
      modifiedBy: null,
      _resourceName: 'layoutTemplate',
      _system: SYSTEM_CMS,
    }
  }

  const createLayoutTemplateTargetPosition = (layoutTemplate: LayoutTemplate): LayoutTemplateTargetPosition => {
    return {
      id: 0,
      enabled: true,
      position: 0,
      layoutTemplate: layoutTemplate.id,
      targetPosition: 0,
      targetPositionDetail: createTargetPosition(),
      _resourceName: 'layoutTemplateTargetPosition',
      _system: SYSTEM_CMS,
    }
  }

  return {
    createLayoutTemplate,
    createLayoutTemplateTargetPosition,
  }
}
