import type { Immutable } from '@anzusystems/common-admin'
import { objectDeepFreeze } from '@anzusystems/common-admin'

const routes = {
  DEFAULT: 'dashboard',
  SYSTEM: {
    UNAUTHORIZED: 'unauthorized',
    LOGIN: 'login',
    LOGOUT: 'logout',
    HOMEPAGE: 'homepage',
    NOT_FOUND: 'notfound',
    SETTINGS: 'settings',
    DASHBOARD: 'dashboard',
  },
  COMMON: {
    LOG: {
      LIST: 'common_log_list',
      DETAIL: 'common_log_detail',
    },
    PERMISSION_GROUP: {
      LIST: 'common_permissionGroup_list',
      DETAIL: 'common_permissionGroup_detail',
      CREATE: 'common_permissionGroup_create',
      EDIT: 'common_permissionGroup_edit',
    },
    CUSTOM_FORM: {
      LIST: 'common_customForm_list',
      DETAIL: 'common_customForm_detail',
      EDIT: 'common_customForm_edit',
    },
  },
  CONTENT_HUB: {
    EXTERNAL_CONTENT: {
      LIST: 'cms_externalContent_list',
      DETAIL: 'cms_externalContent_detail',
    },
    EXTERNAL_SOURCE: {
      DETAIL: 'cms_externalSource_detail',
    },
    EXTERNAL_RUBRIC: {
      DETAIL: 'cms_externalRubric_detail',
    },
  },
  CMS: {
    _PLAYGROUND: {
      COLLAB_DETAIL: 'cms_playground_collabDetail',
      COLLAB_EDIT: 'cms_playground_collabEdit',
    },
    RUBRIC: {
      LIST: 'cms_rubric_list',
      DETAIL: 'cms_rubric_detail',
      EDIT: 'cms_rubric_edit',
      CREATE: 'cms_rubric_create',
    },
    ADVERT_SETTINGS: {
      LIST: 'cms_advertSettings_list',
      DETAIL: 'cms_advertSettings_detail',
      EDIT: 'cms_advertSettings_edit',
      CREATE: 'cms_advertSettings_create',
    },
    ARTICLE_ADVERT_SETTINGS: {
      LIST: 'cms_articleAdvertSettings_list',
      DETAIL: 'cms_articleAdvertSettings_detail',
      EDIT: 'cms_articleAdvertSettings_edit',
      CREATE: 'cms_articleAdvertSettings_create',
    },
    ARTICLE: {
      LIST: 'cms_article_list',
      DETAIL: 'cms_article_detail',
      EDIT: 'cms_article_edit',
      REDIRECT_DETAIL: 'cms_article_redirectDetail',
      REDIRECT_EDIT: 'cms_article_redirectEdit',
      EMPTY: 'cms_article_empty',
    },
    ARTICLE_MINUTE: {
      LIST: 'cms_articleMinute_list',
      DETAIL: 'cms_articleMinute_detail',
      EDIT: 'cms_articleMinute_edit',
    },
    AUTHOR: {
      LIST: 'cms_author_list',
      DETAIL: 'cms_author_detail',
      EDIT: 'cms_author_edit',
      CREATE: 'cms_author_create',
    },
    DESIGN_SETTINGS: {
      LIST: 'cms_designSettings_list',
      DETAIL: 'cms_designSettings_detail',
      EDIT: 'cms_designSettings_edit',
      CREATE: 'cms_designSettings_create',
    },
    EXTERNAL_SNIPPET: {
      LIST: 'cms_externalSnippet_list',
      DETAIL: 'cms_externalSnippet_detail',
      EDIT: 'cms_externalSnippet_edit',
      CREATE: 'cms_externalSnippet_create',
    },
    LAYOUT_TEMPLATE: {
      LIST: 'cms_layoutTemplate_list',
      DETAIL: 'cms_layoutTemplate_detail',
      EDIT: 'cms_layoutTemplate_edit',
      CREATE: 'cms_layoutTemplate_create',
    },
    KEYWORD: {
      LIST: 'cms_keyword_list',
      DETAIL: 'cms_keyword_detail',
      EDIT: 'cms_keyword_edit',
      CREATE: 'cms_keyword_create',
    },
    KEYWORD_MANAGEMENT: {
      LIST: 'cms_keywordManagement_list',
    },
    JOB: {
      LIST: 'cms_job_list',
      DETAIL: 'cms_job_detail',
    },
    SITE: {
      LIST: 'cms_site_list',
      DETAIL: 'cms_site_detail',
      EDIT: 'cms_site_edit',
      CREATE: 'cms_site_create',
    },
    SITE_GROUP: {
      LIST: 'cms_siteGroup_list',
      DETAIL: 'cms_siteGroup_detail',
      EDIT: 'cms_siteGroup_edit',
      CREATE: 'cms_siteGroup_create',
    },
    NEWSLETTER: {
      LIST: 'cms_newsletter_list',
      DETAIL: 'cms_newsletter_detail',
      EDIT: 'cms_newsletter_edit',
      CREATE: 'cms_newsletter_create',
    },
    NEWSLETTER_PROMO: {
      LIST: 'cms_newsletterPromo_list',
      DETAIL: 'cms_newsletterPromo_detail',
      EDIT: 'cms_newsletterPromo_edit',
      CREATE: 'cms_newsletterPromo_create',
    },
    NEWSROOM_SECTION: {
      LIST: 'cms_newsroomSection_list',
      DETAIL: 'cms_newsroomSection_detail',
      EDIT: 'cms_newsroomSection_edit',
      CREATE: 'cms_newsroomSection_create',
    },
    POLL: {
      LIST: 'cms_poll_list',
      DETAIL: 'cms_poll_detail',
      EDIT: 'cms_poll_edit',
      CREATE: 'cms_poll_create',
    },
    QUIZ: {
      LIST: 'cms_quiz_list',
      DETAIL: 'cms_quiz_detail',
      EDIT: 'cms_quiz_edit',
      CREATE: 'cms_quiz_create',
    },
    STAGE: {
      LIST: 'cms_stage_list',
      DETAIL: 'cms_stage_detail',
      EDIT: 'cms_stage_edit',
      CREATE: 'cms_stage_create',
      DASHBOARD: 'cms_stage_dashboard',
    },
    PROMO_LINK: {
      LIST: 'cms_promoLink_list',
      DETAIL: 'cms_promoLink_detail',
      EDIT: 'cms_promoLink_edit',
      CREATE: 'cms_promoLink_create',
    },
    ROUTE_SETTINGS: {
      LIST: 'cms_routeSettings_list',
      DETAIL: 'cms_routeSettings_detail',
      EDIT: 'cms_routeSettings_edit',
      CREATE: 'cms_routeSettings_create',
    },
    REVIEW: {
      LIST: 'cms_review_list',
      DETAIL: 'cms_review_detail',
      EDIT: 'cms_review_edit',
      CREATE: 'cms_review_create',
    },
    SUPER_AUTHOR: {
      LIST: 'cms_superAuthor_list',
      DETAIL: 'cms_superAuthor_detail',
      EDIT: 'cms_superAuthor_edit',
      CREATE: 'cms_superAuthor_create',
    },
    ROUTE: {
      LIST: 'cms_route_list',
      DETAIL: 'cms_route_detail',
      EDIT: 'cms_route_edit',
      CREATE: 'cms_route_create',
    },
    ARTICLE_ARCHIVE: {
      LIST: 'cms_articleArchive_list',
      DETAIL: 'cms_articleArchive_detail',
    },
    BOX: {
      LIST: 'cms_box_list',
      DETAIL: 'cms_box_detail',
      EDIT: 'cms_box_edit',
      CREATE: 'cms_box_create',
    },
    INBOX: {
      LIST: 'cms_inbox_list',
      DETAIL: 'cms_inbox_detail',
      EDIT: 'cms_inbox_edit',
      CREATE: 'cms_inbox_create',
    },
    GALLERY: {
      LIST: 'cms_gallery_list',
      DETAIL: 'cms_gallery_detail',
      EDIT: 'cms_gallery_edit',
    },
    BOX_GROUP: {
      LIST: 'cms_boxGroup_list',
      DETAIL: 'cms_boxGroup_detail',
      EDIT: 'cms_boxGroup_edit',
      CREATE: 'cms_boxGroup_create',
    },
    DISTRIBUTION_MANAGEMENT: {
      LIST: 'cms_distributionManagement_list',
    },
    TEMPLATE: {
      LIST: 'cms_template_list',
      DETAIL: 'cms_template_detail',
      EDIT: 'cms_template_edit',
      CREATE: 'cms_template_create',
    },
    TARGET_POSITION: {
      LIST: 'cms_targetPosition_list',
      DETAIL: 'cms_targetPosition_detail',
      EDIT: 'cms_targetPosition_edit',
      CREATE: 'cms_targetPosition_create',
    },
    TIMELINE: {
      LIST: 'cms_timeline_list',
      DETAIL: 'cms_timeline_detail',
      EDIT: 'cms_timeline_edit',
      CREATE: 'cms_timeline_create',
    },
    PAYWALL: {
      LIST: 'cms_paywall_list',
      DETAIL: 'cms_paywall_detail',
      EDIT: 'cms_paywall_edit',
      CREATE: 'cms_paywall_create',
    },
    FAQ: {
      LIST: 'cms_faq_list',
      DETAIL: 'cms_faq_detail',
      EDIT: 'cms_faq_edit',
      CREATE: 'cms_faq_create',
    },
    LINKED_LIST: {
      LIST: 'cms_linkedList_list',
      DETAIL: 'cms_linkedList_detail',
      TEST: 'cms_linkedList_test',
      EDIT: 'cms_linkedList_edit',
      CREATE: 'cms_linkedList_create',
    },
    PAGE: {
      LIST: 'cms_page_list',
      DETAIL: 'cms_page_detail',
      EDIT: 'cms_page_edit',
    },
    COUNTDOWN: {
      LIST: 'cms_countdown_list',
      DETAIL: 'cms_countdown_detail',
      EDIT: 'cms_countdown_edit',
      CREATE: 'cms_countdown_create',
    },
    CROSS_BOX: {
      LIST: 'cms_crossBox_list',
      DETAIL: 'cms_crossBox_detail',
      EDIT: 'cms_crossBox_edit',
    },
    MINUTE_TOPIC: {
      LIST: 'cms_minuteTopic_list',
      DETAIL: 'cms_minuteTopic_detail',
      EDIT: 'cms_minuteTopic_edit',
      CREATE: 'cms_minuteTopic_create',
    },
    PUBLIC_EXPORT: {
      LIST: 'cms_publicExport_list',
      DETAIL: 'cms_publicExport_detail',
      EDIT: 'cms_publicExport_edit',
      CREATE: 'cms_publicExport_create',
    },
    AUTO_DISTRIBUTION: {
      LIST: 'cms_autoDistribution_list',
      DETAIL: 'cms_autoDistribution_detail',
      EDIT: 'cms_autoDistribution_edit',
      CREATE: 'cms_autoDistribution_create',
    },
    WIZARD: {
      LIST: 'cms_wizard_list',
    },
    PERSON: {
      LIST: 'cms_person_list',
      DETAIL: 'cms_person_detail',
      EDIT: 'cms_person_edit',
      CREATE: 'cms_person_create',
    },
    ORGANIZATION: {
      LIST: 'cms_organization_list',
      DETAIL: 'cms_organization_detail',
      EDIT: 'cms_organization_edit',
      CREATE: 'cms_organization_create',
    },
    EVENT: {
      LIST: 'cms_event_list',
      DETAIL: 'cms_event_detail',
      EDIT: 'cms_event_edit',
      CREATE: 'cms_event_create',
    },
    PERSON_POSITION: {
      LIST: 'cms_person_position_list',
      DETAIL: 'cms_person_position_detail',
      EDIT: 'cms_person_position_edit',
      CREATE: 'cms_person_position_create',
    },
    PERSON_PAGE: {
      LIST: 'cms_person_page_list',
      DETAIL: 'cms_person_page_detail',
      EDIT: 'cms_person_page_edit',
      CREATE: 'cms_person_page_create',
    },
    ORGANIZATION_PAGE: {
      LIST: 'cms_organization_page_list',
      DETAIL: 'cms_organization_page_detail',
      EDIT: 'cms_organization_page_edit',
      CREATE: 'cms_organization_page_create',
    },
    DESK: {
      LIST: 'cms_desk_list',
      DETAIL: 'cms_desk_detail',
      DASHBOARD: 'cms_desk_dashboard',
      EDIT: 'cms_desk_edit',
      CREATE: 'cms_desk_create',
    },
    TASK: {
      LIST: 'cms_task_list',
      DETAIL: 'cms_task_detail',
      EDIT: 'cms_task_edit',
      CREATE: 'cms_task_create',
    },
    ARTICLE_INTENTION: {
      LIST: 'cms_articleIntention_list',
      DETAIL: 'cms_articleIntention_detail',
      EDIT: 'cms_articleIntention_edit',
      CREATE: 'cms_articleIntention_create',
    },
    TRENDING_ARTICLE_GROUP_LIST: {
      LIST: 'cms_trendingArticleGroupList_list',
      DETAIL: 'cms_trendingArticleGroupList_detail',
      EDIT: 'cms_trendingArticleGroupList_edit',
      CREATE: 'cms_trendingArticleGroupList_create',
    },
    USER: {
      LIST: 'common_user_list',
      DETAIL: 'common_user_detail',
      EDIT: 'common_user_edit',
      CREATE: 'common_user_create',
      DASHBOARD: 'common_user_dashboard',
    },
  },
  DAM: {},
}

export const ROUTE: Immutable<typeof routes> = objectDeepFreeze(routes)
