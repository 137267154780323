import { fetchAuthorListByIds } from '@/services/api/cms/authorApi'
import type { AuthorKind, AuthorMinimal } from '@/types/cms/AuthorKind/AuthorKind'
import { isAuthorKindPerson } from '@/types/cms/AuthorKind/AuthorKindPerson'
import { isAuthorKindSource } from '@/types/cms/AuthorKind/AuthorKindSource'
import type { IntegerId } from '@anzusystems/common-admin'
import { defineCached } from '@anzusystems/common-admin'

const mapFullToMinimal = (author: AuthorKind): AuthorMinimal => {
  if (isAuthorKindPerson(author)) {
    return {
      id: author.id,
      displayName: author.person.fullName,
      description: author.jobDescription,
    }
  }
  if (isAuthorKindSource(author)) {
    return {
      id: author.id,
      displayName: author.title,
      description: '',
    }
  }
  return {
    id: author.id,
    displayName: '',
    description: '',
  }
}

const mapIdToMinimal = (id: IntegerId): AuthorMinimal => {
  return { id: id, displayName: '', description: '' }
}

const { cache, toFetch, fetch, add, addManual, addManualMinimal, has, get, isLoaded } = defineCached<
  IntegerId,
  AuthorKind,
  AuthorMinimal
>(mapFullToMinimal, mapIdToMinimal, fetchAuthorListByIds)

export const useCachedAuthors = () => {
  return {
    addManualToCachedAuthors: addManual,
    addManualMinimalToCachedAuthors: addManualMinimal,
    addToCachedAuthors: add,
    fetchCachedAuthors: fetch,
    toFetchCachedAuthors: toFetch,
    cachedAuthors: cache,
    hasCachedAuthor: has,
    getCachedAuthor: get,
    isLoadedCachedAuthor: isLoaded,
  }
}
