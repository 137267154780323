import { LinkVariantDefault } from '@/components/anzutap/marks/link/composables/LinkVariant'
import {
  EmbedImageAlignDefault,
  type EmbedImageAware,
  type EmbedImageCreateUpdateDto,
  EmbedImageLayoutDefault,
} from '@/components/anzutap/types/EmbedImage'
import { SYSTEM_CMS } from '@/model/systems'
import { dateTimeNow } from '@anzusystems/common-admin'

export function useEmbedImageFactory() {
  const createDefault = (id = ''): EmbedImageAware => {
    return {
      id,
      image: null,
      link: {
        href: '',
        external: true,
        nofollow: false,
        variant: LinkVariantDefault,
        internal: null,
      },
      customData: {},
      layout: EmbedImageLayoutDefault,
      align: EmbedImageAlignDefault,
      discriminator: 'image',
      _resourceName: 'embedKindImage',
      _system: SYSTEM_CMS,
      createdAt: dateTimeNow(),
      modifiedAt: dateTimeNow(),
      createdBy: null,
      modifiedBy: null,
    }
  }

  const createCreateUpdateDto = (id = ''): EmbedImageCreateUpdateDto => {
    return {
      id,
      image: null,
      link: {
        href: '',
        external: true,
        nofollow: false,
        variant: LinkVariantDefault,
        internal: null,
      },
      customData: {},
      layout: EmbedImageLayoutDefault,
      align: EmbedImageAlignDefault,
    }
  }

  return {
    createDefault,
    createCreateUpdateDto,
  }
}
