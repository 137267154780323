import { ROUTE } from '@/router/routes'
import {
  createRubric,
  deleteRubric,
  fetchRubric,
  fetchRubricList,
  fetchRubricListByIds,
  updateRubric,
} from '@/services/api/cms/rubricApi'
import { useRubricOneStore } from '@/stores/cms/rubricStore'
import type { Rubric } from '@/types/cms/Rubric'
import { useCachedAdvertSettings } from '@/views/cms/advertSettings/composables/cachedAdvertSettings'
import { useCachedDesignSettings } from '@/views/cms/designSettings/composables/cachedDesignSettings'
import { useCachedLinkedList } from '@/views/cms/linkedList/composables/cachedLinkedList'
import { useCachedPages } from '@/views/cms/page/composables/cachedPages'
import { RubricValidationSymbol } from '@/views/cms/rubric/composables/rubricValidations'
import { useCachedSites } from '@/views/cms/site/composables/cachedSites'
import {
  type FilterBag,
  type IntegerId,
  isUndefined,
  type Pagination,
  useAlerts,
  type ValueObjectOption,
} from '@anzusystems/common-admin'
import useVuelidate from '@vuelidate/core'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'
import { useRouter } from 'vue-router'

const { showValidationError, showRecordWas, showErrorsDefault } = useAlerts()

const { addToCachedSites, fetchCachedSites } = useCachedSites()
const { addToCachedDesignSettings, fetchCachedDesignSettings } = useCachedDesignSettings()
const { addToCachedAdvertSettings, fetchCachedAdvertSettings } = useCachedAdvertSettings()
const { addToCachedLinkedLists, fetchCachedLinkedLists } = useCachedLinkedList()
const { addToCachedPages, fetchCachedPages } = useCachedPages()

const datatableHiddenColumns = ref<Array<string>>([])
const listLoading = ref(false)
const detailLoading = ref(false)
const saveButtonLoading = ref(false)

export const useRubricSelectActions = () => {
  const fetchItems = async (pagination: Pagination, filterBag: FilterBag) => {
    const rubrics = await fetchRubricList(pagination, filterBag)

    return <ValueObjectOption<IntegerId>[]>rubrics.map((rubric: Rubric) => ({
      title: rubric.texts.title,
      value: rubric.id,
    }))
  }

  const fetchItemsByIds = async (ids: IntegerId[]) => {
    const rubrics = await fetchRubricListByIds(ids)

    return <ValueObjectOption<IntegerId>[]>rubrics.map((rubric: Rubric) => ({
      title: rubric.texts.title,
      value: rubric.id,
    }))
  }

  return {
    fetchItems,
    fetchItemsByIds,
  }
}

export const useRubricListActions = () => {
  const listItems = ref<Rubric[]>([])

  const fetchList = async (pagination: Pagination, filterBag: FilterBag) => {
    listLoading.value = true
    try {
      listItems.value = await fetchRubricList(pagination, filterBag)
      listItems.value.forEach((item) => {
        addToCachedSites(item.site)
      })
      fetchCachedSites()
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      listLoading.value = false
    }
  }

  return {
    listLoading,
    datatableHiddenColumns,
    fetchList,
    listItems,
  }
}

export const useRubricDetailActions = () => {
  const rubricOneStore = useRubricOneStore()
  const { rubric } = storeToRefs(rubricOneStore)

  const fetchData = async (id: IntegerId) => {
    detailLoading.value = true
    try {
      const rubricRes = await fetchRubric(id)
      rubric.value = rubricRes
      addToCachedPages(rubricRes.mainPage)
      addToCachedSites(rubricRes.site)
      addToCachedDesignSettings(rubricRes.designSettings)
      addToCachedAdvertSettings(rubricRes.advertSettings)
      addToCachedLinkedLists(rubricRes.linkedList)
      fetchCachedPages()
      fetchCachedSites()
      fetchCachedDesignSettings()
      fetchCachedAdvertSettings()
      fetchCachedLinkedLists()
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      detailLoading.value = false
    }
  }

  return {
    rubric,
    detailLoading,
    fetchData,
    resetStore: rubricOneStore.reset,
  }
}

export const useRubricCreateEditActions = () => {
  const v$ = useVuelidate({ $scope: RubricValidationSymbol })
  const rubricOneStore = useRubricOneStore()
  const { rubric } = storeToRefs(rubricOneStore)

  const fetchData = async (id: IntegerId) => {
    detailLoading.value = true
    try {
      rubric.value = await fetchRubric(id)
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      detailLoading.value = false
    }
  }

  const onUpdate = async (onSuccess: ((rubric: Rubric) => void) | undefined = undefined) => {
    try {
      saveButtonLoading.value = true
      v$.value.$touch()
      if (v$.value.$invalid) {
        showValidationError()
        saveButtonLoading.value = false
        return
      }
      const res = await updateRubric(rubricOneStore.rubric.id, rubricOneStore.rubric)
      showRecordWas('updated')
      if (!isUndefined(onSuccess)) onSuccess(res)
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      saveButtonLoading.value = false
    }
  }

  const onCreate = async (onSuccess: ((rubric: Rubric) => void) | undefined = undefined) => {
    try {
      saveButtonLoading.value = true
      v$.value.$touch()
      if (v$.value.$invalid) {
        showValidationError()
        saveButtonLoading.value = false
        return
      }
      const rubricRes = await createRubric(rubricOneStore.rubric)
      showRecordWas('created')
      if (!isUndefined(onSuccess)) onSuccess(rubricRes)
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      saveButtonLoading.value = false
    }
  }

  return {
    saveButtonLoading,
    detailLoading,
    rubric,
    fetchData,
    onUpdate,
    onCreate,
    resetStore: rubricOneStore.reset,
  }
}

export const useRubricDeleteActions = () => {
  const router = useRouter()

  const onDelete = async (id: IntegerId) => {
    try {
      await deleteRubric(id)
      showRecordWas('deleted')
      router.push({ name: ROUTE.CMS.RUBRIC.LIST })
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      // closeDeleteDialog()
    }
  }

  return {
    onDelete,
  }
}
