<script lang="ts" setup>
import {
  type AppNotification,
  type AppNotificationMessageJob
} from '@/composables/appNotification/appNotificationEventBus'
import { JOB_RESOURCE_ROUTE_REGENERATE, useJobResource } from '@/model/cms/valueObject/JobResource'
import { ROUTE } from '@/router/routes'
import { stringToInt, useJobStatus } from '@anzusystems/common-admin'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

const props = withDefaults(
  defineProps<{
    notification: AppNotification
    isNew: boolean
  }>(),
  {}
)

const { t } = useI18n()
const router = useRouter()
const { getJobStatusOption } = useJobStatus()
const { getJobResourceOption } = useJobResource()

const onClick = () => {
  router.push({ name: ROUTE.CMS.JOB.DETAIL, params: { id: stringToInt(props.notification.message.subjectId) } })
}

const title = computed(() => {
  return (
    t('notification.notification.job.text1') +
    ' ' +
    getJobResourceOption(JOB_RESOURCE_ROUTE_REGENERATE).title +
    ' (ID:' +
    props.notification.message.subjectId +
    ') ' +
    t('notification.notification.job.text2') +
    ' ' +
    getJobStatusOption((props.notification.message as unknown as AppNotificationMessageJob).attributes.status).title)
})
</script>

<template>
  <VListItem
    :color="isNew ? 'success' : undefined"
    :active="isNew"
    lines="one"
    :title="title"
    @click.stop="onClick"
  />
</template>
