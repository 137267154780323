import { ACL } from '@/composables/auth/auth'
import { ROUTE } from '@/router/routes'
import type { RouteRecordRaw } from 'vue-router'

export const cmsDistributionManagementRoutes: RouteRecordRaw[] = [
  {
    path: '/distribution-management',
    name: ROUTE.CMS.DISTRIBUTION_MANAGEMENT.LIST,
    component: () => import('@/views/cms/distributionManagement/DistributionManagementView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_BOX_READ, ACL.CMS_BOX_GROUP_READ],
      layout: 'AppLayoutDrawer',
    },
  },
]
