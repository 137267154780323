import { ACL } from '@/composables/auth/auth'
import { ROUTE } from '@/router/routes'
import type { RouteRecordRaw } from 'vue-router'

export const cmsEventRoutes: RouteRecordRaw[] = [
  {
    path: '/event',
    name: ROUTE.CMS.EVENT.LIST,
    component: () => import('@/views/cms/event/EventListView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/event/create',
    name: ROUTE.CMS.EVENT.CREATE,
    component: () => import('@/views/cms/event/EventCreateView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_EVENT_CREATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/event/:id(\\d+)/edit',
    name: ROUTE.CMS.EVENT.EDIT,
    component: () => import('@/views/cms/event/EventEditView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_EVENT_READ, ACL.CMS_EVENT_UPDATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/event/:id(\\d+)',
    name: ROUTE.CMS.EVENT.DETAIL,
    component: () => import('@/views/cms/event/EventDetailView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_EVENT_READ],
      layout: 'AppLayoutDrawer',
    },
  },
]
