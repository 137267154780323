import { ACL } from '@/composables/auth/auth'
import { ROUTE } from '@/router/routes'
import type { RouteRecordRaw } from 'vue-router'

export const cmsOrganizationPageRoutes: RouteRecordRaw[] = [
  {
    path: '/organization-page',
    name: ROUTE.CMS.ORGANIZATION_PAGE.LIST,
    component: () => import('@/views/cms/organizationPage/OrganizationPageListView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/organization-page/create',
    name: ROUTE.CMS.ORGANIZATION_PAGE.CREATE,
    component: () => import('@/views/cms/organizationPage/OrganizationPageCreateView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_ORGANIZATION_PAGE_CREATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/organization-page/:id(\\d+)/edit',
    name: ROUTE.CMS.ORGANIZATION_PAGE.EDIT,
    component: () => import('@/views/cms/organizationPage/OrganizationPageEditView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_ORGANIZATION_PAGE_READ, ACL.CMS_ORGANIZATION_PAGE_UPDATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/organization-page/:id(\\d+)',
    name: ROUTE.CMS.ORGANIZATION_PAGE.DETAIL,
    component: () => import('@/views/cms/organizationPage/OrganizationPageDetailView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_ORGANIZATION_PAGE_READ],
      layout: 'AppLayoutDrawer',
    },
  },
]
