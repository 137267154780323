<script lang="ts" setup>
import {
  type AppNotification,
  type AppNotificationMessageTask
} from '@/composables/appNotification/appNotificationEventBus'
import { ROUTE } from '@/router/routes'
import { stringToInt } from '@anzusystems/common-admin'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    notification: AppNotification
    isNew: boolean
  }>(),
  {}
)

const { t } = useI18n()
const router = useRouter()

const onClick = () => {
  router.push({ name: ROUTE.CMS.TASK.DETAIL, params: { id: stringToInt(props.notification.message.subjectId) } })
}

const title = computed(() => {
  return (
    t(
      'notification.notification.task.text',
      { descriptionShort:
          (props.notification.message as unknown as AppNotificationMessageTask).attributes.descriptionShort ?? ''
      }
    )
  )
})

</script>

<template>
  <VListItem
    :color="isNew ? 'success' : undefined"
    :active="isNew"
    lines="two"
    :title="title"
    @click.stop="onClick"
  />
</template>
