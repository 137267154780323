<script setup lang="ts">
import { useArticleKindOneStore } from '@/stores/cms/articleKindStore'
import { useArticleMultiTitleOneStore } from '@/stores/cms/articleMultiTitleStore'
import type { CollabRoom } from '@/types/Collab'
import { type ArticleMultiTitle } from '@/types/cms/ArticleMultiTitle'
import { useArticleMultiTitleValidation } from '@/views/cms/articleMultiTitle/composables/articleMultiTitleValidation'
import { useCachedUsers } from '@/views/cms/user/composables/cachedUsers'
import {
  AFormTextarea,
  CollabStatus,
  isString,
  useCollabHelpers,
  useCollabState,
  useCommonAdminCollabOptions,
} from '@anzusystems/common-admin'
import { storeToRefs } from 'pinia'
import { computed, onMounted, watch } from 'vue'
import { useI18n } from 'vue-i18n'

const props = withDefaults(
  defineProps<{
    collabRoom: CollabRoom
    readonly: boolean
    isModerator: boolean
  }>(),
  {}
)

const emit = defineEmits<{
  (e: 'changed'): void
}>()

const modelValue = defineModel<ArticleMultiTitle>({ required: true })

const articleKindOneStore = useArticleKindOneStore()
const { article } = storeToRefs(articleKindOneStore)
const articleMultiTitleOneStore = useArticleMultiTitleOneStore()
const { articleMultiTitles } = storeToRefs(articleMultiTitleOneStore)

watch(
  () => article.value.texts,
  (newValue) => {
    if (!modelValue.value.inUse) return
    if (newValue.headline !== modelValue.value.headline) {
      modelValue.value.headline = newValue.headline
    }
    if (newValue.perex !== modelValue.value.perex) {
      modelValue.value.perex = newValue.perex
    }
  },
  { deep: true }
)

const onChangeMultiTitle = () => {
  articleMultiTitles.value.forEach((item) => (item.inUse = false))
  article.value.texts.headline = modelValue.value.headline
  article.value.texts.perex = modelValue.value.perex
  modelValue.value.inUse = true
  emit('changed')
}

const onChangeUsedMultiTitleTitle = (data: unknown) => {
  if (isString(data)) {
    modelValue.value.headline = data
    if (modelValue.value.inUse) article.value.texts.headline = data
  }
}

const onChangeUsedMultiTitlePerex = (data: unknown) => {
  if (isString(data)) {
    modelValue.value.perex = data
    if (modelValue.value.inUse) article.value.texts.perex = data
  }
}

const { v$ } = useArticleMultiTitleValidation(modelValue)

onMounted(() => v$.value.$touch())

const { collabRoomInfoState } = useCollabState()
const { collabOptions } = useCommonAdminCollabOptions()
const { cachedUsers } = useCachedUsers()
const { createCollabFieldConfig } = useCollabHelpers()

const collabActive = computed(() => {
  const roomInfo = collabRoomInfoState.get(props.collabRoom) ?? {
    name: props.collabRoom,
    moderator: null,
    users: [],
    status: CollabStatus.Inactive,
  }
  return collabOptions.value.enabled && roomInfo.status === CollabStatus.Active
})

const collabHeadlineFieldConfig = () => {
  if (modelValue.value.inUse) return createCollabFieldConfig('texts.headline', props.collabRoom, cachedUsers.value)
  return createCollabFieldConfig(`multiTitleHeadline:${modelValue.value.variant}`, props.collabRoom, cachedUsers.value)
}

const collabPerexFieldConfig = () => {
  if (modelValue.value.inUse) return createCollabFieldConfig('texts.perex', props.collabRoom, cachedUsers.value)
  return createCollabFieldConfig(`multiTitlePerex:${modelValue.value.variant}`, props.collabRoom, cachedUsers.value)
}

const disabledComputed = computed(() => {
  return v$.value.multiTitle.$invalid || collabActive.value || props.readonly
})

const { t } = useI18n()
</script>

<template>
  <VRow>
    <VCol cols="10">
      <VRow>
        <VCol cols="12">
          <AFormTextarea
            v-model="modelValue.headline"
            class="mt-5"
            :label="t('cms.articleMultiTitle.model.headline')"
            :collab="collabHeadlineFieldConfig()"
            :v="v$.multiTitle.headline"
            :readonly="readonly"
            required
            counter
            persistent-counter
            :maxlength="255"
            @update:model-value="(data: unknown) => onChangeUsedMultiTitleTitle(data)"
          />
        </VCol>
        <VCol
          cols="12"
          class="mt-0 pt-0"
        >
          <AFormTextarea
            v-model="modelValue.perex"
            :label="t('cms.articleMultiTitle.model.perex')"
            :collab="collabPerexFieldConfig()"
            :v="v$.multiTitle.perex"
            :readonly="readonly"
            counter
            persistent-counter
            :maxlength="4096"
            :suggested-length="160"
            @update:model-value="(data: unknown) => onChangeUsedMultiTitlePerex(data)"
          />
        </VCol>
      </VRow>
    </VCol>
    <VCol
      cols="2"
      align-self="center"
    >
      <div>
        <VCheckboxBtn
          v-model="modelValue.inUse"
          color="primary"
          inline
          :disabled="disabledComputed"
          :label="modelValue.variant.toUpperCase()"
          @change="onChangeMultiTitle"
        />
      </div>
    </VCol>
  </VRow>
</template>
