<script lang="ts" setup>
import { useArticleArticleStageFilter } from '@/model/cms/filter/ArticleStageFilter'
import { SYSTEM_CMS } from '@/model/systems'
import { ENTITY } from '@/services/api/cms/articleStageApi'
import ArticleStageTableServer from '@/views/cms/articleStage/components/ArticleStageTableServer.vue'
import { useArticleStageListActions } from '@/views/cms/articleStage/composables/articleStageActions'
import {
  createDatatableColumnsConfig,
  type DocId
} from '@anzusystems/common-admin'
import { onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { TABLE_KEY } from '@/composables/system/tableStoreKey'

const props = withDefaults(
  defineProps<{
    docId: DocId
  }>(),
  {}
)

const filter = useArticleArticleStageFilter()
const { fetchList, datatableHiddenColumns } = useArticleStageListActions()

const { t } = useI18n()

const { columnsVisible, pagination } = createDatatableColumnsConfig(
  [
    { key: 'stage', title: t('cms.articleStage.datatable.stage') },
    { key: 'createdAt' },
    { key: 'completedAt' },
  ],
  datatableHiddenColumns,
  SYSTEM_CMS,
  ENTITY,
  undefined,
  undefined,
  undefined,
  undefined,
  TABLE_KEY.articleStage,
)

pagination.sortBy = null

const getList = () => {
  fetchList(pagination, filter)
}

onMounted(() => {
  filter.docId.model = props.docId
  pagination.rowsPerPage = 5
  getList()
})

defineExpose({
  refresh: getList,
})
</script>

<template>
  <div>
    <ArticleStageTableServer
      :columns-visible="columnsVisible"
    />
  </div>
</template>
