import type { AxiosInstance, AxiosRequestConfig } from 'axios'
import axios from 'axios'
import { envConfig } from '@/services/EnvConfigService'
import { isNull } from '@anzusystems/common-admin'
import { SYSTEM_ADMIN_CMS } from '@/model/systems'
import { userRefreshRequestInterceptor } from '@/services/api/clients/interceptors/requestRefreshToken'
import { AUTH_PATH_PREFIX } from '@/services/api/cms/authApi'

let mainInstance: AxiosInstance | null = null

const owlClient = function (): AxiosInstance {
  if (isNull(mainInstance)) {
    mainInstance = axios.create({
      baseURL: envConfig.owl.apiUrl,
      timeout: envConfig.owl.apiTimeout * 1000,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        'X-App-Version': SYSTEM_ADMIN_CMS + '-' + envConfig.appVersion,
      },
    })
    mainInstance.interceptors.request.use(userRefreshRequestInterceptor, undefined, {
      runWhen: (requestConfig: AxiosRequestConfig): boolean =>
        !(requestConfig.url?.startsWith(AUTH_PATH_PREFIX) ?? false),
    })
  }

  return mainInstance
}

export { owlClient }
