import type { CreateTabType, Page } from '@/types/cms/Page'
import { CreateTab } from '@/types/cms/Page'
import { useValidate } from '@anzusystems/common-admin'
import useVuelidate from '@vuelidate/core'
import { requiredIf } from '@vuelidate/validators'
import type { Ref } from 'vue'

export const PageValidationSymbol = Symbol.for('anzu:cms:page-validation-scope')

export function usePageEditValidation(page: Ref<Page>) {
  const { maxLength, minLength, required, slug, minValue, maxValue } = useValidate()

  const rules = {
    page: {
      texts: {
        title: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(255),
        },
        description: {
          maxLength: maxLength(4096),
        },
      },
      seo: {
        title: {
          maxLength: maxLength(255),
        },
        description: {
          maxLength: maxLength(255),
        },
        slug: {
          slug,
          maxLength: maxLength(255),
        },
        keywords: {
          maxLength: maxLength(255),
        },
        indexingType: {},
      },
      analytics: {
        rempPropertyToken: {
          maxLength: maxLength(255),
        },
      },
      flags: {
        useLinkedListFromParent: {},
        useDesignSettingsFromParent: {},
        useAdvertSettingsFromParent: {},
        useAnalyticsFromParent: {},
        useDesignSettingsHeaderWithMainHeadline: {},
        template: {},
        shownAsRelated: {},
      },
      site: {
        required,
        minValue: minValue(1),
      },
      siteGroup: {
        required,
        minValue: minValue(1),
      },
      monitoring: {
        daysThreshold: {
          required: requiredIf(() => {
            return page.value.monitoring.enabled
          }),
          maxValue: maxValue(180),
        },
      },
      enabled: {},
      parent: {},
      rubric: {},
      owners: {},
      supervisors: {},
      linkedList: {},
      layoutTemplate: {
        required,
      },
      advertSettings: {},
      designSettings: {},
      excludedAutoDistributions: {},
    },
  }
  const v$ = useVuelidate(rules, { page }, { $scope: PageValidationSymbol })

  return {
    v$,
  }
}

export function usePageCreateValidation(page: Ref<Page>, createTab: Ref<CreateTabType>) {
  const { maxLength, minLength, required, requiredIf, slug } = useValidate()

  const rules = {
    page: {
      texts: {
        title: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(255),
        },
      },
      seo: {
        slug: {
          slug,
          maxLength: maxLength(255),
        },
      },
      parent: {
        required: requiredIf(() => {
          return createTab.value === CreateTab.CreateFromLayoutTemplate
        }),
      },
      siteGroup: {
        required: requiredIf(() => {
          return createTab.value === CreateTab.CreateAsNew
        }),
      },
      site: {
        required: requiredIf(() => {
          return createTab.value === CreateTab.CreateAsNew
        }),
      },
      rubric: {},
      layoutTemplate: {
        required: requiredIf(() => {
          return createTab.value === CreateTab.CreateAsNew
        }),
      },
    },
  }
  const v$ = useVuelidate(rules, { page }, { $scope: false })

  return {
    v$,
  }
}
