import { ref } from 'vue'
import type { Newsletter } from '@/types/cms/Newsletter'
import type { FilterBag, IntegerId, Pagination, ValueObjectOption } from '@anzusystems/common-admin'
import { deleteNewsletter, fetchNewsletterList, fetchNewsletterListByIds } from '@/services/api/cms/newsletterApi'
import { useAlerts } from '@anzusystems/common-admin'
import { useNewsletterOneStore } from '@/stores/cms/newsletterStore'
import { storeToRefs } from 'pinia'
import { createNewsletter, fetchNewsletter, updateNewsletter } from '@/services/api/cms/newsletterApi'
import { useRouter } from 'vue-router'
import { ROUTE } from '@/router/routes'
import useVuelidate from '@vuelidate/core'
import { useCachedNewsletterPromos } from '@/views/cms/newsletterPromo/composables/cachedNewsletterPromos'
import { useCachedSites } from '@/views/cms/site/composables/cachedSites'
import { useCachedRubrics } from '@/views/cms/rubric/composables/cachedRubrics'
import { NewsletterValidationSymbol } from '@/views/cms/newsletter/composables/newsletterValidation'

const { showValidationError, showRecordWas, showErrorsDefault } = useAlerts()
const { addToCachedNewsletterPromos, fetchCachedNewsletterPromos } = useCachedNewsletterPromos()
const { addToCachedSites, fetchCachedSites } = useCachedSites()
const { addToCachedRubrics, fetchCachedRubrics } = useCachedRubrics()

const datatableHiddenColumns = ref<Array<string>>([])
const listLoading = ref(false)
const detailLoading = ref(false)
const saveButtonLoading = ref(false)

export const useNewsletterSelectActions = () => {
  const fetchItems = async (pagination: Pagination, filterBag: FilterBag) => {
    const newsletterList = await fetchNewsletterList(pagination, filterBag)

    return <ValueObjectOption<IntegerId>[]>newsletterList.map((newsletter: Newsletter) => ({
      title: newsletter.texts.title,
      value: newsletter.id,
    }))
  }

  const fetchItemsByIds = async (ids: number[]) => {
    const newsletterList = await fetchNewsletterListByIds(ids)
    return <ValueObjectOption<IntegerId>[]>newsletterList.map((newsletter: Newsletter) => ({
      value: newsletter.id,
      title: newsletter.texts.title,
    }))
  }

  return {
    fetchItems,
    fetchItemsByIds,
  }
}

export const useNewsletterDetailActions = () => {
  const newsletterOneStore = useNewsletterOneStore()
  const { newsletter, newsletterLoaded } = storeToRefs(newsletterOneStore)

  const fetchData = async (id: number) => {
    detailLoading.value = true
    try {
      const newsletterRes = await fetchNewsletter(id)
      newsletter.value = newsletterRes
      addToCachedNewsletterPromos(newsletterRes.newsletterPromos)
      fetchCachedNewsletterPromos()
      addToCachedSites(newsletterRes.site)
      fetchCachedSites()
      addToCachedRubrics(newsletterRes.rubric)
      fetchCachedRubrics()
      newsletterLoaded.value = true
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      detailLoading.value = false
    }
  }

  return {
    newsletter,
    detailLoading,
    fetchData,
    resetStore: newsletterOneStore.reset,
  }
}

export const useNewsletterDeleteActions = () => {
  const router = useRouter()

  const onDelete = async (id: number) => {
    try {
      await deleteNewsletter(id)
      showRecordWas('deleted')
      router.push({ name: ROUTE.CMS.NEWSLETTER.LIST })
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      // closeDeleteDialog()
    }
  }

  return {
    onDelete,
  }
}

export const useNewsletterCreateEditActions = () => {
  const v$ = useVuelidate({ $scope: NewsletterValidationSymbol })
  const router = useRouter()
  const newsletterOneStore = useNewsletterOneStore()
  const { newsletter, newsletterLoaded } = storeToRefs(newsletterOneStore)

  const fetchData = async (id: number) => {
    detailLoading.value = true
    try {
      newsletter.value = await fetchNewsletter(id)
      newsletterLoaded.value = true
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      detailLoading.value = false
    }
  }

  const onUpdate = async (close = false) => {
    try {
      saveButtonLoading.value = true
      v$.value.$touch()
      if (v$.value.$invalid) {
        showValidationError()
        saveButtonLoading.value = false
        return
      }
      await updateNewsletter(newsletterOneStore.newsletter.id, newsletterOneStore.newsletter)
      showRecordWas('updated')
      if (!close) return
      router.push({ name: ROUTE.CMS.NEWSLETTER.LIST })
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      saveButtonLoading.value = false
    }
  }

  const onCreate = async (close = false) => {
    try {
      saveButtonLoading.value = true
      v$.value.$touch()
      if (v$.value.$invalid) {
        showValidationError()
        saveButtonLoading.value = false
        return
      }
      const newsletterRes = await createNewsletter(newsletterOneStore.newsletter)
      showRecordWas('created')
      if (close) {
        router.push({ name: ROUTE.CMS.NEWSLETTER.LIST })
        return
      }
      router.push({ name: ROUTE.CMS.NEWSLETTER.DETAIL, params: { id: newsletterRes.id } })
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      saveButtonLoading.value = false
    }
  }

  return {
    detailLoading,
    saveButtonLoading,
    newsletter,
    newsletterLoaded,
    fetchData,
    onUpdate,
    onCreate,
    resetStore: newsletterOneStore.reset,
  }
}

export const useNewsletterListActions = () => {
  const listItems = ref<Newsletter[]>([])
  const selectedNewsletterList = ref<Newsletter[]>([])

  const fetchList = async (pagination: Pagination, filterBag: FilterBag) => {
    listLoading.value = true
    try {
      listItems.value = await fetchNewsletterList(pagination, filterBag)
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      listLoading.value = false
    }
  }

  return {
    datatableHiddenColumns,
    listLoading,
    fetchList,
    listItems,
    selectedNewsletterList,
  }
}
