import type { SYSTEM_CMS } from '@/model/systems'
import type { ContentItemKindTypes } from '@/types/cms/ContentItemKind/ContentItemKind'
import type { LimitedDisplayTypeType } from '@/types/cms/LimitedDisplayType'
import type {
  AnzuUserAndTimeTrackingAware,
  DatetimeUTCNullable,
  IntegerId,
  IntegerIdNullable,
} from '@anzusystems/common-admin'

export const AutoDistributionType = {
  System: 'system',
  Operator: 'operator',
} as const
export const AutoDistributionTypeDefault = AutoDistributionType.System
export type AutoDistributionTypeType = (typeof AutoDistributionType)[keyof typeof AutoDistributionType]

export interface AutoDistribution extends AnzuUserAndTimeTrackingAware {
  id: IntegerId
  texts: AutoDistributionTexts
  dates: AutoDistributionDates
  attributes: AutoDistributionAttributes
  targetPosition: IntegerIdNullable
  enabled: boolean
  positionInsideTargetPosition: number
  siteGroup: IntegerIdNullable
  site: IntegerIdNullable
  rubric: IntegerIdNullable
  keywords: IntegerId[]
  contentItem: ContentItemKindTypes
  _resourceName: 'autoDistribution'
  _system: typeof SYSTEM_CMS
}

export interface AutoDistributionAttributes {
  type: AutoDistributionTypeType
  limitedDisplayType: LimitedDisplayTypeType
}

export interface AutoDistributionTexts {
  title: string
  description: string
}

export interface AutoDistributionDates {
  showAtFrom: DatetimeUTCNullable
  showAtUntil: DatetimeUTCNullable
}

export interface AutoDistributionMinimal {
  id: IntegerId
  title: string
}

export interface GroupedAutoDistributions {
  [targetPositionId: number]: AutoDistribution[]
}
