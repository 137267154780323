import { defineStore } from 'pinia'
import { ref } from 'vue'
import type { ArticleSumMetrics } from '@/types/owl/ArticleSumMetrics'
import { useArticleSumMetricsFactory } from '@/model/cms/factory/ArticleSumMetricsFactory'

export const useArticleSumMetricsOneStore = defineStore('cmsArticleSumMetricsOneStore', () => {
  const { createDefault } = useArticleSumMetricsFactory()

  const articleSumMetrics = ref<ArticleSumMetrics>(createDefault())

  function reset() {
    articleSumMetrics.value = createDefault()
  }

  return {
    articleSumMetrics,
    reset,
  }
})
