import { SYSTEM_CMS } from '@/model/systems'
import type { ApiInfiniteResponseList, DocId, FilterBag, IntegerId, Pagination } from '@anzusystems/common-admin'
import {
  apiAnyRequest,
  apiCreateOne,
  apiDeleteOne,
  apiFetchList,
  apiFetchOne,
  apiUpdateOne,
  useApiQueryBuilder,
} from '@anzusystems/common-admin'
import { cmsClient } from '@/services/api/clients/cmsClient'
import type { RouteKind, RouteKindBase } from '@/types/cms/RouteKind/RouteKind'
import { RouteStatus } from '@/model/cms/valueObject/RouteStatus'
import { RouteDiscriminator } from '@/types/cms/RouteKind/RouteKind'

const END_POINT = '/adm/v1/route-kind'
export const ENTITY = 'routeKind'

export const fetchArticleActiveAndReservedRouteList = (docId: DocId) => {
  const { queryBuild, querySetOrder, queryAddFilter, querySetLimit } = useApiQueryBuilder()
  querySetOrder('id', true)
  querySetLimit(5)
  queryAddFilter('eq', 'articleDocId', docId)
  queryAddFilter('in', 'status', [RouteStatus.Active, RouteStatus.Reserved].join(','))

  return apiAnyRequest<ApiInfiniteResponseList<RouteKindBase[]>>(
    cmsClient,
    'get',
    END_POINT + '/article' + queryBuild(),
    {},
    null,
    SYSTEM_CMS,
    ENTITY
  )
}

export const fetchArticleActiveRouteList = (docId: DocId) => {
  const { queryBuild, querySetOrder, queryAddFilter, querySetLimit } = useApiQueryBuilder()
  querySetOrder('id', true)
  querySetLimit(5)
  queryAddFilter('eq', 'articleDocId', docId)
  queryAddFilter('in', 'status', [RouteStatus.Active].join(','))

  return apiAnyRequest<ApiInfiniteResponseList<RouteKindBase[]>>(
    cmsClient,
    'get',
    END_POINT + '/article' + queryBuild(),
    {},
    null,
    SYSTEM_CMS,
    ENTITY
  )
}

export const fetchArticleSuggestedRoute = (id: IntegerId) => {
  return apiAnyRequest<RouteKindBase>(
    cmsClient,
    'get',
    END_POINT + '/article/suggest/:id',
    { id },
    null,
    SYSTEM_CMS,
    ENTITY
  )
}

export const reserveArticleSuggestedRoute = (id: IntegerId) => {
  return apiAnyRequest<RouteKindBase>(
    cmsClient,
    'patch',
    END_POINT + '/article/reserve/:id',
    { id },
    null,
    SYSTEM_CMS,
    ENTITY
  )
}

export const fetchPageActiveRouteList = (id: IntegerId) => {
  const { queryBuild, querySetOrder, queryAddFilter, querySetLimit } = useApiQueryBuilder()
  querySetOrder('id', true)
  querySetLimit(5)
  queryAddFilter('eq', 'page', id)
  queryAddFilter('in', 'status', [RouteStatus.Active].join(','))

  return apiAnyRequest<ApiInfiniteResponseList<RouteKindBase[]>>(
    cmsClient,
    'get',
    END_POINT + '/page' + queryBuild(),
    {},
    null,
    SYSTEM_CMS,
    ENTITY
  )
}

export const fetchAuthorActiveRouteList = (id: IntegerId) => {
  const { queryBuild, querySetOrder, queryAddFilter, querySetLimit } = useApiQueryBuilder()
  querySetOrder('id', true)
  querySetLimit(5)
  queryAddFilter('custom', 'entityIdentifier', id)
  queryAddFilter('custom', 'discriminator', RouteDiscriminator.Author)
  queryAddFilter('eq', 'status', RouteStatus.Active)

  return apiAnyRequest<ApiInfiniteResponseList<RouteKindBase[]>>(
    cmsClient,
    'get',
    END_POINT + queryBuild(),
    {},
    null,
    SYSTEM_CMS,
    ENTITY
  )
}

export const fetchPersonActiveRouteList = (id: IntegerId) => {
  const { queryBuild, querySetOrder, queryAddFilter, querySetLimit } = useApiQueryBuilder()
  querySetOrder('id', true)
  querySetLimit(5)
  queryAddFilter('eq', 'person', id)
  queryAddFilter('in', 'status', [RouteStatus.Active].join(','))

  return apiAnyRequest<ApiInfiniteResponseList<RouteKindBase[]>>(
    cmsClient,
    'get',
    END_POINT + '/person' + queryBuild(),
    {},
    null,
    SYSTEM_CMS,
    ENTITY
  )
}

export const fetchOrganizationActiveRouteList = (id: IntegerId) => {
  const { queryBuild, querySetOrder, queryAddFilter, querySetLimit } = useApiQueryBuilder()
  querySetOrder('id', true)
  querySetLimit(5)
  queryAddFilter('eq', 'organization', id)
  queryAddFilter('in', 'status', [RouteStatus.Active].join(','))

  return apiAnyRequest<ApiInfiniteResponseList<RouteKindBase[]>>(
    cmsClient,
    'get',
    END_POINT + '/organization' + queryBuild(),
    {},
    null,
    SYSTEM_CMS,
    ENTITY
  )
}

export const fetchRouteList = (pagination: Pagination, filterBag: FilterBag) =>
  apiFetchList<RouteKindBase[]>(cmsClient, END_POINT, {}, pagination, filterBag, SYSTEM_CMS, ENTITY)

export const fetchRoute = (id: IntegerId) =>
  apiFetchOne<RouteKindBase>(cmsClient, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)

export const createRoute = (data: RouteKindBase) => {
  return apiCreateOne<RouteKindBase>(
    cmsClient,
    data,
    END_POINT + '/:discriminator',
    { discriminator: data.discriminator },
    SYSTEM_CMS,
    ENTITY
  )
}

export const updateRoute = (id: IntegerId, data: RouteKindBase) =>
  apiUpdateOne<RouteKindBase>(
    cmsClient,
    data,
    END_POINT + '/:discriminator/:id',
    { id, discriminator: data.discriminator },
    SYSTEM_CMS,
    ENTITY
  )

export const deleteRoute = (id: IntegerId) =>
  apiDeleteOne<RouteKindBase>(cmsClient, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)

export const fetchRouteByUriFull = (uriFull: string) => {
  const { queryBuild, querySetOrder, queryAddFilter, querySetLimit } = useApiQueryBuilder()
  querySetOrder('id', true)
  querySetLimit(1)
  queryAddFilter('eq', 'uri.full', uriFull)

  return apiAnyRequest<ApiInfiniteResponseList<RouteKind[]>>(
    cmsClient,
    'get',
    END_POINT + queryBuild(),
    {},
    null,
    SYSTEM_CMS,
    ENTITY
  )
}
