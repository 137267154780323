import { SYSTEM_CMS } from '@/model/systems'
import { cmsClient } from '@/services/api/clients/cmsClient'
import type { BoxItem, BoxItemsRemoveTextsOverrideDto } from '@/types/cms/BoxItem'
import type { DatetimeUTCNullable, FilterBag, IntegerId, Pagination } from '@anzusystems/common-admin'
import {
  apiAnyRequest,
  apiCreateOne,
  apiDeleteOne,
  apiFetchList,
  apiFetchOne,
  apiUpdateOne,
} from '@anzusystems/common-admin'

const END_POINT = '/adm/v1/box-item'
export const ENTITY = 'boxItem'

export const fetchBoxItemList = (pagination: Pagination, filterBag: FilterBag) =>
  apiFetchList<BoxItem[]>(cmsClient, END_POINT, {}, pagination, filterBag, SYSTEM_CMS, ENTITY)

export const fetchBoxItem = (id: IntegerId) =>
  apiFetchOne<BoxItem>(cmsClient, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)

export const createBoxItem = (data: BoxItem) =>
  apiCreateOne<BoxItem>(cmsClient, data, END_POINT, {}, SYSTEM_CMS, ENTITY)

export const createBoxItemAndShiftNext = (data: BoxItem) =>
  apiCreateOne<BoxItem>(cmsClient, data, END_POINT + '/shift', {}, SYSTEM_CMS, ENTITY)

export const updateBoxItem = (id: IntegerId, data: BoxItem) =>
  apiUpdateOne<BoxItem>(cmsClient, data, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)

export const deleteBoxItem = (id: IntegerId) =>
  apiDeleteOne<BoxItem>(cmsClient, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)

export const moveBoxItem = (
  boxItemId: number,
  newPosition: number,
  newBoxId: number,
  newPublishedSince: DatetimeUTCNullable,
  newPublishedUntil: DatetimeUTCNullable = null
) => {
  const boxItemMoveDto = {
    box: newBoxId,
    position: newPosition,
    publishedSince: newPublishedSince,
    publishedUntil: newPublishedUntil,
  }
  return apiAnyRequest(
    cmsClient,
    'PATCH',
    END_POINT + '/' + boxItemId + '/move',
    {},
    boxItemMoveDto,
    SYSTEM_CMS,
    ENTITY
  )
}

export const moveBoxItemAndShiftNext = (
  boxItemId: number,
  newPosition: number,
  newBoxId: number,
  newPublishedSince: DatetimeUTCNullable,
  newPublishedUntil: DatetimeUTCNullable = null
) => {
  const boxItemMoveDto = {
    box: newBoxId,
    position: newPosition,
    publishedSince: newPublishedSince,
    publishedUntil: newPublishedUntil,
  }
  return apiAnyRequest(
    cmsClient,
    'PATCH',
    END_POINT + '/' + boxItemId + '/move/shift',
    {},
    boxItemMoveDto,
    SYSTEM_CMS,
    ENTITY
  )
}

export const removeTextsOverridesBoxItems = (data: BoxItemsRemoveTextsOverrideDto) =>
  apiAnyRequest(cmsClient, 'PATCH', END_POINT + '/remove-texts-override', {}, data, SYSTEM_CMS, ENTITY)
