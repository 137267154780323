import type { LayoutTemplate, LayoutTemplateTargetPosition } from '@/types/cms/LayoutTemplate'
import { useValidate } from '@anzusystems/common-admin'
import useVuelidate from '@vuelidate/core'
import type { Ref } from 'vue'

export const LayoutTemplateValidationSymbol = Symbol.for('anzu:cms:layout-template-validation-scope')

export function useLayoutTemplateValidation(layoutTemplate: Ref<LayoutTemplate>) {
  const { maxLength, minLength, required } = useValidate()
  const rules = {
    layoutTemplate: {
      texts: {
        title: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(255),
        },
        description: {
          maxLength: maxLength(2000),
        },
      },
      attributes: {
        webEntityType: {
          required,
        },
        articleWebAdvertType: {
          required,
        },
        default: {},
        wideForm: {},
      },
    },
  }
  const v$ = useVuelidate(rules, { layoutTemplate }, { $scope: LayoutTemplateValidationSymbol })

  return {
    v$,
  }
}

export function useLayoutTemplateTargetPositionValidation(
  layoutTemplateTargetPosition: Ref<LayoutTemplateTargetPosition>
) {
  const { required } = useValidate()
  const rules = {
    layoutTemplateTargetPosition: {
      targetPosition: {
        required,
      },
      enabled: {},
    },
  }
  const v$ = useVuelidate(rules, { layoutTemplateTargetPosition }, { $scope: LayoutTemplateValidationSymbol })

  return {
    v$,
  }
}
