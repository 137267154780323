<script lang="ts" setup>
import { useAuth } from '@/composables/auth/auth'
import {
  ATableDetailButton,
  ATableEditButton,
  type DocId,
  eventClickBlur,
  type IntegerId,
  isArray,
  isUndefined,
  useAlerts,
} from '@anzusystems/common-admin'
import { useClipboard } from '@vueuse/core'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

const props = withDefaults(
  defineProps<{
    id: IntegerId | DocId
    overrideCopyId?: undefined | IntegerId | DocId
    detailRouteName?: undefined | string | false
    detailRouteParams?: any | undefined
    detailRouteAcl?: undefined | any // todo fix AclValue type
    editRouteName?: undefined | string | false
    editRouteParams?: any | undefined
    editRouteAcl?: undefined | any // todo fix AclValue type
    disableCopyId?: boolean
    disableMore?: boolean
    showDetailButtonInMenu?: boolean
    isMenuResponsive?: boolean
    customEditCallback?: boolean
  }>(),
  {
    overrideCopyId: undefined,
    detailRouteName: undefined,
    detailRouteParams: undefined,
    detailRouteAcl: undefined,
    editRouteName: undefined,
    editRouteParams: undefined,
    editRouteAcl: undefined,
    disableCopyId: false,
    disableMore: false,
    showDetailButtonInMenu: false,
    isMenuResponsive: false,
    customEditCallback: false,
  }
)

const emit = defineEmits<{
  (e: 'onEditClick'): void
}>()

const routeToDetail = computed(() => {
  if (!isUndefined(props.detailRouteParams)) {
    return { name: props.detailRouteName, params: { ...props.detailRouteParams } }
  }
  return { name: props.detailRouteName, params: { id: props.id } }
})

const { t } = useI18n()
const { copy, isSupported } = useClipboard()
const { showSuccess } = useAlerts()

const onCopyIdClick = (event: Event) => {
  eventClickBlur(event)
  const toBeCopied = isUndefined(props.overrideCopyId) ? props.id + '' : props.overrideCopyId + ''
  copy(toBeCopied).then(() => {
    showSuccess(t('common.alert.idWasCopied'))
  })
}

const { can, canForAll } = useAuth()

const showDetailButton = computed(() => {
  if (isUndefined(props.detailRouteName) || props.detailRouteName === false || props.detailRouteName.length === 0) {
    return false
  }
  if (isUndefined(props.detailRouteAcl) || props.detailRouteAcl.length === 0) {
    return true
  }

  return isArray(props.detailRouteAcl) ? canForAll(props.detailRouteAcl as any) : can(props.detailRouteAcl)
})

const showEditButton = computed(() => {
  if (isUndefined(props.editRouteName) || props.editRouteName === false || props.editRouteName.length === 0) {
    return false
  }
  if (isUndefined(props.editRouteAcl) || props.editRouteAcl.length === 0) {
    return true
  }

  return isArray(props.editRouteAcl) ? canForAll(props.editRouteAcl as any) : can(props.editRouteAcl)
})

const showMoreIcons = computed(() => {
  if (props.disableMore) return false
  return isSupported.value || showDetailButton.value
})

// todo => move to common?
</script>

<template>
  <div
    :class="{ 'a-table-actions--responsive': isMenuResponsive }"
    class="a-table-actions d-flex justify-end align-center"
  >
    <slot name="before" />
    <slot name="action-button">
      <VBtn
        v-if="showEditButton && customEditCallback"
        class="btn--a-table-action mr-1"
        data-cy="table-edit"
        icon
        size="x-small"
        variant="text"
        @click.stop="emit('onEditClick')"
      >
        <VIcon icon="mdi-pencil" />
        <VTooltip
          activator="parent"
          location="bottom"
        >
          {{ t('common.button.edit') }}
        </VTooltip>
      </VBtn>
      <ATableEditButton
        v-else-if="showEditButton"
        :record-id="id"
        :route-name="editRouteName as string"
        :route-params="editRouteParams"
        button-class="btn--a-table-action mr-1"
      />
      <ATableDetailButton
        v-else-if="showDetailButton"
        :record-id="id"
        :route-name="detailRouteName as string"
        :route-params="detailRouteParams"
        button-class="btn--a-table-action mr-1"
      />
    </slot>
    <VMenu
      v-if="showMoreIcons"
      location="bottom end"
      origin="top end"
    >
      <template #activator="{ props: menuProps }">
        <VBtn
          v-bind="menuProps"
          icon
          size="x-small"
          variant="text"
          class="btn--a-table-menu"
        >
          <VIcon
            size="large"
            icon="mdi-dots-vertical"
          />
          <VTooltip
            anchor="bottom"
            activator="parent"
            :text="t('system.datatable.moreActions')"
          />
        </VBtn>
      </template>
      <VList
        density="compact"
        class="a-table-actions__list"
      >
        <slot name="more">
          <slot name="more-start" />
          <slot name="detail-button">
            <VListItem
              v-if="(showEditButton && showDetailButton) || showDetailButtonInMenu"
              :to="routeToDetail as any"
              :title="t('common.button.detail')"
            />
          </slot>
          <VListItem
            v-if="!disableCopyId && isSupported"
            :title="t('common.button.copyId')"
            @click="onCopyIdClick"
          />
          <slot
            :id="id"
            name="more-end"
          />
        </slot>
      </VList>
    </VMenu>
    <slot name="after" />
  </div>
</template>
