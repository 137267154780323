import useVuelidate from '@vuelidate/core'
import type { Ref, WritableComputedRef } from 'vue'
import { useValidate, type ValidationScope } from '@anzusystems/common-admin'
import type { EmbedRelatedAware } from '@/components/anzutap/types/EmbedRelated'
import type { RelatedItemKindArticle, RelatedItemKindLink } from '@/types/cms/RelatedItem'

export const EmbedRelatedValidationSymbol = Symbol.for('anzu:common:embed-related-validation-scope')

export function useEmbedRelatedDialogValidation(embed: Ref<EmbedRelatedAware>) {
  const { maxLength, minLength, required } = useValidate()

  const rules = {
    embed: {
      title: {
        minLength: minLength(3),
        maxLength: maxLength(255),
      },
      items: {
        required,
        minLength: minLength(1),
      },
    },
  }

  const v$ = useVuelidate(rules, { embed }, { $scope: EmbedRelatedValidationSymbol })

  return {
    v$,
  }
}

export function useEmbedRelatedDialogItemLinkValidation(
  item: WritableComputedRef<RelatedItemKindLink>,
  validationScope?: ValidationScope
) {
  const { maxLength, minLength, required, url } = useValidate()

  const rules = {
    item: {
      url: {
        required,
        minLength: minLength(10),
        maxLength: maxLength(2048),
        url,
      },
      overline: {
        maxLength: maxLength(255),
      },
      headline: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(255),
      },
      perex: {
        maxLength: maxLength(4096),
      },
      image: {},
      external: {},
      nofollow: {},
    },
  }

  const v$ = useVuelidate(rules, { item }, { $scope: validationScope })

  return {
    v$,
  }
}

export function useEmbedRelatedDialogItemArticleValidation(
  item: WritableComputedRef<RelatedItemKindArticle>,
  validationScope?: ValidationScope
) {
  const { maxLength } = useValidate()

  const rules = {
    item: {
      overline: {
        maxLength: maxLength(255),
      },
      headline: {
        maxLength: maxLength(255),
      },
      perex: {
        maxLength: maxLength(4096),
      },
    },
  }

  const v$ = useVuelidate(rules, { item }, { $scope: validationScope })

  return {
    v$,
  }
}
