import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import type { ValueObjectOption } from '@anzusystems/common-admin'

export const ArticleStandardIcon = {
  None: 'none',
  Gallery: 'gallery',
  Video: 'video',
  VideoBig: 'videoBig',
  Audio: 'audio',
  Tv: 'tv',
  Live: 'live',
} as const
export type ArticleStandardIconType = (typeof ArticleStandardIcon)[keyof typeof ArticleStandardIcon]

export function useArticleStandardIcon() {
  const { t } = useI18n()

  const articleStandardIconOptions = ref<ValueObjectOption<ArticleStandardIconType>[]>([
    {
      value: ArticleStandardIcon.None,
      title: t('cms.articleKind.articleStandardIcon.none'),
    },
    {
      value: ArticleStandardIcon.Gallery,
      title: t('cms.articleKind.articleStandardIcon.gallery'),
    },
    {
      value: ArticleStandardIcon.Video,
      title: t('cms.articleKind.articleStandardIcon.video'),
    },
    {
      value: ArticleStandardIcon.VideoBig,
      title: t('cms.articleKind.articleStandardIcon.videoBig'),
    },
    {
      value: ArticleStandardIcon.Audio,
      title: t('cms.articleKind.articleStandardIcon.audio'),
    },
    {
      value: ArticleStandardIcon.Tv,
      title: t('cms.articleKind.articleStandardIcon.tv'),
    },
    {
      value: ArticleStandardIcon.Live,
      title: t('cms.articleKind.articleStandardIcon.live'),
    },
  ])

  const getArticleStandardIconOption = (value: ArticleStandardIconType) => {
    return articleStandardIconOptions.value.find((item) => item.value === value)
  }

  return {
    articleStandardIconOptions,
    getArticleStandardIconOption,
  }
}
