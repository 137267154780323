<script lang="ts" setup>
import { AFormDatetimePicker, AFormTextarea, useAlerts, usePagination } from '@anzusystems/common-admin'
import { usePropagationSuggestFactory } from '@/model/cms/factory/PropagationSuggestFactory'
import { useI18n } from 'vue-i18n'
import { usePropagationSuggestValidation } from '@/views/cms/propagationSuggest/composables/propagationSuggestValidation'
import { computed, onMounted, ref } from 'vue'
import InboxRemoteAutocomplete from '@/views/cms/inbox/components/InboxRemoteAutocomplete.vue'
import type { PropagationSuggest } from '@/types/cms/PropagationSuggest'
import { usePropagationSuggestListActions } from '@/views/cms/propagationSuggest/composables/propagationSuggestActions'
import { usePropagationSuggestListFilter } from '@/model/cms/filter/PropagationSuggestFilter'
import { createPropagationSuggest, updatePropagationSuggest } from '@/services/api/cms/propagationSuggestApi'
import { PropagationSuggestPriority } from '@/model/cms/valueObject/PropagationSuggestPriority'
import { usePropagationSuggestConfirmation } from '@/model/cms/valueObject/PropagationSuggestConfirmation'

const props = withDefaults(
  defineProps<{
    articleDocId: string
    showRequired?: boolean
  }>(),
  {
    showRequired: false,
  }
)

const { t } = useI18n()
const { createDefault } = usePropagationSuggestFactory()

const propagationSuggest = ref<PropagationSuggest>(createDefault())
const isEditing = ref<boolean>(false)
const isShowAdvanced = ref<boolean>(false)

const { v$ } = usePropagationSuggestValidation(ref(propagationSuggest))
const { listItems, fetchList } = usePropagationSuggestListActions()
const { getPropagationConfirmationIcon } = usePropagationSuggestConfirmation()
const pagination = usePagination()
const { filter } = usePropagationSuggestListFilter()
const { showSuccess, showValidationError } = useAlerts()

const onClickShowAdvanced = () => {
  isShowAdvanced.value = !isShowAdvanced.value
}

const saveRecord = async () => {
  v$.value.$touch()
  if (!v$.value.$invalid) {
    try {
      if (isEditing.value) {
        await updatePropagationSuggest(propagationSuggest.value.id, propagationSuggest.value)
        showSuccess(t('cms.propagationSuggest.message.updated'))
        resetPropagationSuggest()
        await fetchList(pagination, filter)
        return
      }
      await createPropagationSuggest(propagationSuggest.value)
      showSuccess(t('cms.propagationSuggest.message.created'))
      resetPropagationSuggest()
      await fetchList(pagination, filter)
    } catch (error) {
      showValidationError()
    }
    return
  }
  showValidationError()
}

const resetPropagationSuggest = () => {
  propagationSuggest.value = createDefault()
  propagationSuggest.value.articleDocId = props.articleDocId
  isEditing.value = false
  v$.value.$reset()
}

const getForEdit = (suggest: PropagationSuggest) => {
  propagationSuggest.value = suggest
  isEditing.value = true
  v$.value.$reset()
}

const priorityComputed = computed({
  get(): boolean {
    return propagationSuggest.value.attributes.priority === PropagationSuggestPriority.High
  },
  set(newValue: boolean) {
    propagationSuggest.value.attributes.priority = newValue
      ? PropagationSuggestPriority.High
      : PropagationSuggestPriority.Regular
  },
})

onMounted(() => {
  filter.articleDocId.model = props.articleDocId
  propagationSuggest.value.articleDocId = props.articleDocId
  fetchList(pagination, filter)
})
</script>

<template>
  <VCardText>
    <div class="article-propagation">
      <h3 class="subtitle-2 text--disabled mb-5 d-none">
        {{ t('cms.propagationSuggest.meta.siteSuggests') }}
      </h3>
      <div>
        <InboxRemoteAutocomplete
          v-model="propagationSuggest.inbox"
          :label="t('cms.propagationSuggest.model.inbox')"
          :required="showRequired"
        />
      </div>
      <ABtnTertiary
        class="mb-4 px-1"
        size="small"
        @click.stop="onClickShowAdvanced"
      >
        {{ t('cms.propagationSuggest.button.advanced') }}
      </ABtnTertiary>
      <div
        v-if="isShowAdvanced"
        class="article-propagation__advanced"
      >
        <div>
          <VSwitch
            v-model="priorityComputed"
            :label="t('cms.propagationSuggest.model.attributes.priority')"
          />
        </div>
        <div>
          <AFormDatetimePicker
            v-model="propagationSuggest.dates.recommendedPublishAt"
            :label="t('cms.propagationSuggest.model.dates.recommendedPublishAt')"
          />
        </div>
        <div>
          <AFormDatetimePicker
            v-model="propagationSuggest.dates.publishLatestAt"
            :label="t('cms.propagationSuggest.model.dates.publishLatestAt')"
          />
        </div>
        <div>
          <AFormTextarea
            v-model="propagationSuggest.comment"
            :v="v$.propagationSuggest.comment"
            :label="t('cms.propagationSuggest.model.comment')"
          />
        </div>
      </div>
      <div class="d-flex justify-end pb-3 align-center">
        <ABtnTertiary
          class="align-center mt-1"
          @click.stop="saveRecord"
        >
          {{ t(isEditing ? 'cms.propagationSuggest.button.edit' : 'cms.propagationSuggest.button.suggest') }}
        </ABtnTertiary>
        <ABtnIcon
          v-if="isEditing"
          size="small"
          @click.stop="resetPropagationSuggest"
        >
          <VIcon
            color="grey darken-1"
            icon="mdi-close"
          />
          <VTooltip
            anchor="bottom"
            activator="parent"
            :text="t('cms.propagationSuggest.button.close')"
          />
        </ABtnIcon>
      </div>
      <h3 class="subtitle-2 text--disabled">
        {{ t('cms.propagationSuggest.meta.propagationSuggests') }}
      </h3>
      <div>
        <VList
          v-if="listItems.length"
          two-line
        >
          <VListItem
            v-for="suggest in listItems"
            :key="suggest.id"
            :prepend-icon="getPropagationConfirmationIcon(suggest.attributes.confirmation)"
            :title="suggest.inboxName"
            :subtitle="suggest.comment"
          >
            <template #append>
              <ABtnIcon
                icon="mdi-pencil"
                size="small"
                @click.stop="getForEdit(suggest)"
              />
            </template>
          </VListItem>
        </VList>
        <div
          v-else
          class="d-flex text--disabled pt-3"
        >
          {{ t('cms.propagationSuggest.meta.noDataText') }}
        </div>
      </div>
    </div>
  </VCardText>
</template>
