<script setup lang="ts">
import { useArticleKindOneStore } from '@/stores/cms/articleKindStore'
import type { CollabRoom } from '@/types/Collab'
import ArticleRoute from '@/views/cms/article/components/ArticleRoute.vue'
import { useArticleUpdateValidation } from '@/views/cms/article/composables/articleValidations'
import { useCachedUsers } from '@/views/cms/user/composables/cachedUsers'
import { AFormTextarea, ARow, type CollabStatusType, stringToSlug, useCollabHelpers } from '@anzusystems/common-admin'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'

withDefaults(
  defineProps<{
    collabRoom: CollabRoom
    collabStatus: CollabStatusType
    readonly: boolean
    isModerator: boolean
  }>(),
  {}
)

const { createCollabFieldConfig } = useCollabHelpers()

const articleKindOneStore = useArticleKindOneStore()
const { article } = storeToRefs(articleKindOneStore)

const { v$ } = useArticleUpdateValidation(article)

const { t } = useI18n()

const { cachedUsers } = useCachedUsers()

const titleToSlug = () => {
  article.value.seo.slug = stringToSlug(article.value.seo.title)
}
</script>

<template>
  <VCol>
    <ARow>
      <ArticleRoute
        :is-edit="!readonly"
        :collab="createCollabFieldConfig('route', collabRoom, cachedUsers)"
        :is-moderator="isModerator"
      />
    </ARow>
    <ARow>
      <AFormTextarea
        v-model="article.seo.slug"
        :disabled="readonly"
        :v="v$.article.seo.slug"
        :collab="createCollabFieldConfig('seo.slug', collabRoom, cachedUsers)"
      />
    </ARow>
    <ARow class="container-seo-title">
      <div class="seo-title">
        <AFormTextarea
          v-model="article.seo.title"
          :disabled="readonly"
          :v="v$.article.seo.title"
          :collab="createCollabFieldConfig('seo.title', collabRoom, cachedUsers)"
        />
        <div class="seo-title-button">
          <VBtn
            size="small"
            @click.stop="titleToSlug"
          >
            {{ t('cms.articleKind.widget.seoTexts.seoTitleToSlug') }}
          </VBtn>
        </div>
      </div>
    </ARow>
    <ARow>
      <AFormTextarea
        v-model="article.seo.description"
        :disabled="readonly"
        :v="v$.article.seo.description"
        :collab="createCollabFieldConfig('seo.description', collabRoom, cachedUsers)"
      />
    </ARow>
  </VCol>
</template>

<style scoped lang="scss">
.container-seo-title {
  container-type: inline-size;
}

.seo-title {
  display: block;
  margin-bottom: 16px;

  .seo-title-button {
    margin-left: 0;
    text-align: right;
  }

  @container (min-width: 500px) {
    display: flex;
    margin-bottom: 0;

    .seo-title-button {
      margin-left: 8px;
    }
  }
}
</style>
