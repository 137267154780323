import { SYSTEM_CMS } from '@/model/systems'
import { cmsClient } from '@/services/api/clients/cmsClient'
import type { CurrentUserDto, UpdateCurrentUserDto, User } from '@/types/cms/User'
import type { FilterBag, IntegerId, Pagination } from '@anzusystems/common-admin'
import {
  apiAnyRequest,
  apiCreateOne,
  apiFetchByIds,
  apiFetchList,
  apiFetchOne,
  apiUpdateOne,
} from '@anzusystems/common-admin'

const END_POINT = '/adm/users'
export const CURRENT_USER_END_POINT = END_POINT + '/current'
export const ENTITY = 'user'

export const fetchUserListByIds = (ids: IntegerId[]) =>
  apiFetchByIds<User[]>(cmsClient, ids, END_POINT, {}, SYSTEM_CMS, ENTITY)

export const fetchUserList = (pagination: Pagination, filterBag: FilterBag) =>
  apiFetchList<User[]>(cmsClient, END_POINT, {}, pagination, filterBag, SYSTEM_CMS, ENTITY)

export const fetchUser = (id: IntegerId) => apiFetchOne<User>(cmsClient, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)

export const createUser = (data: User) => apiCreateOne<User>(cmsClient, data, END_POINT, {}, SYSTEM_CMS, ENTITY)

export const updateUser = (id: IntegerId, data: User) =>
  apiUpdateOne<User>(cmsClient, data, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)

export const fetchCurrentUser = () =>
  apiFetchOne<CurrentUserDto>(cmsClient, CURRENT_USER_END_POINT, {}, SYSTEM_CMS, 'user')

export const updateCurrentUser = (data: UpdateCurrentUserDto) =>
  apiAnyRequest<UpdateCurrentUserDto, CurrentUserDto>(
    cmsClient,
    'PATCH',
    CURRENT_USER_END_POINT,
    {},
    data,
    SYSTEM_CMS,
    ENTITY
  )
