import useVuelidate from '@vuelidate/core'
import type { Ref } from 'vue'
import { useValidate } from '@anzusystems/common-admin'
import type { AuthorKind } from '@/types/cms/AuthorKind/AuthorKind'
import { AuthorDiscriminator } from '@/model/cms/valueObject/AuthorDiscriminator'

export const AuthorValidationSymbol = Symbol.for('anzu:cms:author-validation-scope')

export function useAuthorValidation(author: Ref<AuthorKind>) {
  const { maxLength, minValue, minLength, required, slug, requiredIf } = useValidate()

  const rules = {
    author: {
      superAuthor: {
        required,
        minValue: minValue(1),
      },
      siteGroup: {
        required,
        minValue: minValue(1),
      },
      site: {
        required,
        minValue: minValue(1),
      },
      slug: {
        required,
        slug,
        minLength: minLength(2),
        maxLength: maxLength(256),
      },
      descriptionShort: {
        minLength: minLength(3),
        maxLength: maxLength(256),
      },
      description: {},
      image: {},
      title: {
        required: requiredIf(() => {
          return author.value.discriminator === AuthorDiscriminator.Source
        }),
        minLength: minLength(2),
        maxLength: maxLength(255),
      },
      person: {
        firstName: {
          required: requiredIf(() => {
            return author.value.discriminator === AuthorDiscriminator.Person
          }),
          minLength: minLength(2),
          maxLength: maxLength(120),
        },
        lastName: {
          required: requiredIf(() => {
            return author.value.discriminator === AuthorDiscriminator.Person
          }),
          minLength: minLength(2),
          maxLength: maxLength(120),
        },
        fullName: {
          required: requiredIf(() => {
            return author.value.discriminator === AuthorDiscriminator.Person
          }),
          minLength: minLength(3),
          maxLength: maxLength(255),
        },
      },
      jobDescription: {},
      settings: {
        enabled: {},
        enabledFollow: {},
        enabledArticleAssign: {},
        enabledArticleShow: {},
        enabledProfile: {},
      },
    },
  }
  const v$ = useVuelidate(rules, { author }, { $scope: AuthorValidationSymbol })

  return {
    v$,
  }
}
