import { useAppNotificationListener } from '@/composables/appNotification/appNotification'
import { AppNotificationMessageType } from '@/composables/appNotification/appNotificationEventBus'
import { useEmbedStore } from '@/stores/cms/embedStore'
import { fetchEmbed } from '@/services/api/cms/embed/embedApi'
import { isUndefined } from '@anzusystems/common-admin'
import { isEmbedExternal } from '@/components/anzutap/types/EmbedExternal'
import { useAppNotificationStore } from '@/composables/appNotification/appNotificationStore'
import { storeToRefs } from 'pinia'
import { watch } from 'vue'
import { useAppNotificationActions } from '@/composables/appNotification/appNotificationActions'
import { useDebounceFn } from '@vueuse/core'

export const NOTIFICATION_UNREAD_MAX = 50

export function appNotificationInit() {
  const { addAppNotificationListener } = useAppNotificationListener()
  const embedStore = useEmbedStore()
  const appNotificationStore = useAppNotificationStore()
  const { appNotificationSidebar, notifications, mode } = storeToRefs(appNotificationStore)
  const { loadCount, clearLoaded, loadNotifications } = useAppNotificationActions()

  const OPEN_DURATION = 2000
  let sidebarOpenTimeOut: ReturnType<typeof setTimeout> | undefined = undefined
  let sidebarOpenDuration = 0

  const refreshOnClose = () => {
    clearLoaded()
  }

  const onSidebarOpen = () => {
    if (notifications.value.length === 0) {
      loadNotifications()
    }
    sidebarOpenTimeOut = setTimeout(() => {
      sidebarOpenDuration += OPEN_DURATION
    }, OPEN_DURATION)
  }

  const onSidebarClose = () => {
    if (sidebarOpenDuration >= OPEN_DURATION) {
      refreshOnClose()
    }
    sidebarOpenDuration = 0
    if (sidebarOpenTimeOut !== undefined) {
      clearTimeout(sidebarOpenTimeOut)
    }
  }

  watch(appNotificationSidebar, (newValue, oldValue) => {
    if (newValue === oldValue) return
    if (newValue) {
      onSidebarOpen()
      return
    }
    onSidebarClose()
  })

  watch(mode, (newValue, oldValue) => {
    if (newValue === oldValue) return
    loadNotifications(true)
  })

  loadCount()

  const loadCountDebounced = useDebounceFn(
    () => {
      loadCount()
    },
    3000,
    { maxWait: 10000 }
  )

  addAppNotificationListener(async (notification) => {
    if (!notification.message.subjectId || notification.message.subjectId.length === 0) return
    switch (notification.message.type) {
      case AppNotificationMessageType.EmbedKindExternal:
        {
          try {
            const res = await fetchEmbed(notification.message.subjectId)
            const embed = embedStore.getEmbed(notification.message.subjectId)
            if (isUndefined(embed) || !isEmbedExternal(embed) || !isEmbedExternal(res)) return
            embed.params = res.params
            embed.data = res.data
            embed.scrapeStatus = res.scrapeStatus
          } catch (e) {
            //
          }
        }
        break
      case AppNotificationMessageType.Notification:
        {
          try {
            loadCount()
          } catch (e) {
            //
          }
        }
        break
      case AppNotificationMessageType.Task:
      case AppNotificationMessageType.Job: {
        appNotificationStore.addNotification(notification)
        loadCountDebounced()
        break
      }
    }
  })
}
