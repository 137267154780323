import {
  type FilterBag,
  type IntegerId,
  isUndefined,
  type Pagination,
  useAlerts,
  type ValueObjectOption,
} from '@anzusystems/common-admin'
import { createSite, fetchSite, fetchSiteList, fetchSiteListByIds, updateSite } from '@/services/api/cms/siteApi'
import useVuelidate from '@vuelidate/core'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'
import type { Site } from '@/types/cms/Site'
import { useSiteOneStore } from '@/stores/cms/siteStore'
import { useCachedLinkedList } from '@/views/cms/linkedList/composables/cachedLinkedList'
import { useCachedDesignSettings } from '@/views/cms/designSettings/composables/cachedDesignSettings'
import { useCachedAdvertSettings } from '@/views/cms/advertSettings/composables/cachedAdvertSettings'
import { useCachedLayoutTemplate } from '@/views/cms/layoutTemplate/composables/cachedLayoutTemplate'
import { SiteValidationSymbol } from '@/views/cms/site/composables/siteValidations'

const { showValidationError, showRecordWas, showErrorsDefault } = useAlerts()

const { addToCachedLinkedLists, fetchCachedLinkedLists } = useCachedLinkedList()
const { addToCachedDesignSettings, fetchCachedDesignSettings } = useCachedDesignSettings()
const { addToCachedAdvertSettings, fetchCachedAdvertSettings } = useCachedAdvertSettings()
const { addToCachedLayoutTemplate, fetchCachedLayoutTemplate } = useCachedLayoutTemplate()

const datatableHiddenColumns = ref<Array<string>>([])
const listLoading = ref(false)
const detailLoading = ref(false)
const saveButtonLoading = ref(false)

export const useSiteListActions = () => {
  const listItems = ref<Site[]>([])

  const fetchList = async (pagination: Pagination, filterBag: FilterBag) => {
    listLoading.value = true
    try {
      listItems.value = await fetchSiteList(pagination, filterBag)
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      listLoading.value = false
    }
  }

  return {
    datatableHiddenColumns,
    listLoading,
    fetchList,
    listItems,
  }
}

export const useSiteSelectActions = () => {
  const fetchItems = async (pagination: Pagination, filterBag: FilterBag) => {
    const sites = await fetchSiteList(pagination, filterBag)

    return <ValueObjectOption<IntegerId>[]>sites.map((site: Site) => ({
      title: site.name,
      value: site.id,
    }))
  }

  const fetchItemsByIds = async (ids: IntegerId[]) => {
    const sites = await fetchSiteListByIds(ids)

    return <ValueObjectOption<IntegerId>[]>sites.map((site: Site) => ({
      title: site.name,
      value: site.id,
    }))
  }

  return {
    fetchItems,
    fetchItemsByIds,
  }
}

export const useSiteDetailActions = () => {
  const siteOneStore = useSiteOneStore()
  const { site } = storeToRefs(siteOneStore)

  const fetchData = async (id: IntegerId) => {
    detailLoading.value = true
    try {
      const siteRes = await fetchSite(id)
      site.value = siteRes
      addToCachedLinkedLists(siteRes.linkedList)
      addToCachedLinkedLists(siteRes.siteMapLinkedList)
      addToCachedDesignSettings(siteRes.designSettings)
      addToCachedAdvertSettings(siteRes.advertSettings)
      addToCachedLayoutTemplate(siteRes.authorLayoutTemplate)
      addToCachedLayoutTemplate(siteRes.galleryLayoutTemplate)
      addToCachedLayoutTemplate(siteRes.forumLayoutTemplate)
      fetchCachedLinkedLists()
      fetchCachedDesignSettings()
      fetchCachedAdvertSettings()
      fetchCachedLayoutTemplate()
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      detailLoading.value = false
    }
  }

  return {
    detailLoading,
    site,
    fetchData,
    resetStore: siteOneStore.reset,
  }
}

export const useSiteCreateEditActions = () => {
  const v$ = useVuelidate({ $scope: SiteValidationSymbol })
  const siteOneStore = useSiteOneStore()
  const { site } = storeToRefs(siteOneStore)

  const fetchData = async (id: IntegerId) => {
    detailLoading.value = true
    try {
      site.value = await fetchSite(id)
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      detailLoading.value = false
    }
  }

  const onUpdate = async (onSuccess: ((site: Site) => void) | undefined = undefined) => {
    try {
      saveButtonLoading.value = true
      v$.value.$touch()
      if (v$.value.$invalid) {
        showValidationError()
        saveButtonLoading.value = false
        return
      }
      const res = await updateSite(siteOneStore.site.id, siteOneStore.site)
      showRecordWas('updated')
      if (!isUndefined(onSuccess)) onSuccess(res)
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      saveButtonLoading.value = false
    }
  }

  const onCreate = async (onSuccess: ((site: Site) => void) | undefined = undefined) => {
    try {
      saveButtonLoading.value = true
      if (v$.value.$invalid) {
        showValidationError()
        saveButtonLoading.value = false
        return
      }
      const siteRes = await createSite(siteOneStore.site)
      showRecordWas('created')
      if (!isUndefined(onSuccess)) onSuccess(siteRes)
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      saveButtonLoading.value = false
    }
  }

  return {
    detailLoading,
    saveButtonLoading,
    site,
    fetchData,
    onUpdate,
    onCreate,
    resetStore: siteOneStore.reset,
  }
}
