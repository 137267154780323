import { ROUTE } from '@/router/routes'
import type { RouteRecordRaw } from 'vue-router'

export const commonAnzuUserRoutes: RouteRecordRaw[] = [
  {
    path: '/user',
    name: ROUTE.CMS.USER.LIST,
    component: () => import('@/views/cms/user/UserListView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/user/create',
    name: ROUTE.CMS.USER.CREATE,
    component: () => import('@/views/cms/user/UserCreateView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/user/:id(\\d+)/edit',
    name: ROUTE.CMS.USER.EDIT,
    component: () => import('@/views/cms/user/UserEditView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/user/:id(\\d+)',
    name: ROUTE.CMS.USER.DETAIL,
    component: () => import('@/views/cms/user/UserDetailView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/user/dashboard',
    name: ROUTE.CMS.USER.DASHBOARD,
    component: () => import('@/views/cms/desk/PersonalDashboardView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
]
