import { useContentItemKindFactory } from '@/model/cms/factory/ContentItemKind/ContentItemKindFactory'
import { ContentItemDiscriminator } from '@/types/cms/ContentItemKind/ContentItemKind'
import type { ContentItemKindAdvert } from '@/types/cms/ContentItemKind/ContentItemKindAdvert'

const { createAbstractContentItemKind } = useContentItemKindFactory()

export function useContentItemKindAdvertFactory() {
  const create = (): ContentItemKindAdvert => {
    return {
      ...createAbstractContentItemKind(),
      adSlotName: '',
      forcedSynchronicity: true,
      discriminator: ContentItemDiscriminator.Advert,
      _resourceName: 'contentItemKindAdvert',
    }
  }

  return {
    create,
  }
}
