import useVuelidate from '@vuelidate/core'
import type { Ref } from 'vue'
import { useValidate } from '@anzusystems/common-admin'
import type { CreateKeywordDto, UpdateKeywordDto } from '@/types/cms/Keyword'

export const KeywordValidationSymbol = Symbol.for('anzu:cms:keyword-validation-scope')

export function useKeywordCreateValidation(keywordCreate: Ref<CreateKeywordDto>) {
  const { maxLength, minLength, required } = useValidate()

  const rules = {
    keywordCreate: {
      name: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(255),
      },
      siteGroup: {
        required,
      },
    },
  }
  const v$ = useVuelidate(rules, { keywordCreate })

  return {
    v$,
  }
}

export function useKeywordUpdateValidation(keywordUpdate: Ref<UpdateKeywordDto>) {
  const { maxLength, minLength, required } = useValidate()

  const rules = {
    keywordUpdate: {
      name: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(255),
      },
    },
  }
  const v$ = useVuelidate(rules, { keywordUpdate }, { $scope: KeywordValidationSymbol })

  return {
    v$,
  }
}
