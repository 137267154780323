import useVuelidate from '@vuelidate/core'
import type { Ref } from 'vue'
import { useValidate } from '@anzusystems/common-admin'
import type { Inbox } from '@/types/cms/Inbox'

export const InboxValidationSymbol = Symbol.for('anzu:cms:inbox-validation-scope')

export function useInboxValidation(inbox: Ref<Inbox>) {
  const { maxLength, minLength, required } = useValidate()

  const rules = {
    inbox: {
      texts: {
        title: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(255),
        },
        description: {
          maxLength: maxLength(4096),
        },
      },
    },
  }
  const v$ = useVuelidate(rules, { inbox }, { $scope: InboxValidationSymbol })

  return {
    v$,
  }
}
